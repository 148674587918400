.donate-form__details {
  background: #1a1a1a;
  margin-left: unset;
  margin-right: unset;
  border-radius: 2px;
  border: solid 0.5px #7A7A7A;
}

.donate-form__label {
  font-weight: unset;
  font-size: 16px;
  text-transform: uppercase;
}

.donate-form__receiver,
.donate-form__amount {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
