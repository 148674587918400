.alert {
  border-radius: 1px;
}

.alert-danger {
  background: #e74d4d;
  color: #fff;
  border: 0px;
  font-size: 1.2rem;
}

.inline-block {
  display: inline-block;
}

label {
  font-weight: normal;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.offset-top {
  margin-top: 1rem;
}

.offset-top-double {
  margin-top: 2rem;
}

// Bootstrap overrides

.panel {
  background: transparent;
  border: none;
}

.panel-heading {
  background: transparent;
}

.form-control {
  background: transparent;
  border-color: $input-border-color;
  border-radius: 1px;
  color: #c2c2c2;
}

.form-control[disabled] {
  color: #c2c2c2;
  border-color: $input-border-color;
  background: #292929;
}

.form-control[readonly] {
  color: inherit;
  border-color: $input-border-color;
  background: inherit;
}

.nav-tabs {
  border-color: $input-border-color;
}

.nav-tabs > li > a {
  cursor: pointer;
  min-height: 38px !important;
  border-radius: 0;
  padding: 8px 15px 6px 15px;
  @media (max-width: $screen-sm-min) {
    padding: 8px 10px 6px 10px;
  }
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus {
  background: #282828;
  border-color: $input-border-color;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus, {
  background: transparent;
  border-color: $input-border-color;
  border-bottom-color: $body-background-color;
  color: #fff;
  letter-spacing: 0.3px;
}

.fv-modal {
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background: $modal-background-color;
    border-color: $input-border-color;
    border-bottom-color: $modal-background-color;
  }
}

.tab-content {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.input-group-addon {
  background: #b8b8b8;
  color: #000000;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  display: inline-block;
}

.error-slug {
  position: absolute;
  margin-top: 38px;
}

.input-error {
  color: #e74d4d;
  &.fixed {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.fv-breadcrumbs {
  margin-top: 5px;
  font-size: 30px;

  a {
    color: #c9c9c9;
  }

  i {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.col-image-editor {
  width: 40%;
  margin-bottom: 20px;

  & + .col-inputs {
    width: 60%;
  }

  @media (max-width: 767px) {
    width: 100%;
    & + .col-inputs {
      width: 100%;
    }
  }
}

.col-inputs {
  @media (max-width: 1150px) and (min-width: 992px) {
    padding-left: 60px;
  }
  @media (min-width: 1250px) {
    margin-left: -15px;
    padding: 0;
  }
}​

.form-cta {
  color: #65a3eb;
  cursor: pointer;
}

.form-group.with-toggle-btn {
  position: relative;

  input {
    padding-right: 45px !important;
  }

  .toggle-input-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    user-select: none;
  }
}

.form-dark {
  .has-error {
    input {
      border-color: $color-error;
    }
  }
  .form-control {
    background-color: #252525;
    border-radius: 2px;
    border-color: transparent;
    box-shadow: none;
    resize: none;
    color: #888;
    &:focus {
      border-color: #31B49C;
    }
  }
  .ember-power-select-trigger {
    border-color: #252525;
    background-color: #252525;
    border-radius: 2px;
    &.ember-basic-dropdown-trigger[aria-expanded=true] {
      border-radius: 2px;
    }
  }
}
