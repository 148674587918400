.profile-edit-section {
  .col-inputs {
    & > .row {
      margin-right: -10px;
      margin-left: -10px;
      & > div {
        padding: 0 10px;
      }
    }
  }
}
