.truncate.max-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: unset;
  &__2 {
    -webkit-line-clamp: 2;
  }
  &__3 {
    -webkit-line-clamp: 3;
  }
  &__4 {
    -webkit-line-clamp: 4;
  }
}
