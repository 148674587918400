.btn {
  border: 0px;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 6px 26px;
  box-shadow: none;
}

.btn-primary {
  background: $primary-color;

  &:hover, &:focus {
    background: lighten($primary-color, 8);
  }

  [disabled]:hover, &:active, &:active:focus, &:hover:active {
    background: darken($primary-color, 14);
  }

  i {
    margin-right: 7px;
  }
}

.btn-little-action {
  background: $primary-color-2;
  color: #ffffff;
  border: 0;
  font-size: 12px;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 2px 6px;
  min-width: 48px;
}

.btn-primary[disabled]:hover {
  background: darken($primary-color, 14);
}

.btn-secondary {
  background: $secondary-color;
  color: #fff;

  &:hover, &:focus {
    background: lighten($secondary-color, 8);
    color: #fff;
  }

  &[disabled]:hover, &:active, &:active:focus, &:hover:active {
    background: lighten($secondary-color, 5);
  }

  i {
    margin-right: 7px;
  }
}

.btn-secondary-2 {
  background: #888;

  &:hover, &:focus {
    background: lighten(#888, 8);
    color: #fff;
  }

  &[disabled]:hover, &:active, &:active:focus, &:hover:active {
    background: lighten(#888, 5);
  }
}

.btn-variant-1 {
  border: 1px solid $input-border-color;
  padding: 5px 26px;
  background: transparent;
  color: #FFFFFF;
  &:hover, &:focus {
    color: inherit;
  }
}

.btn-primary-2 {
  background: $primary-color-2;

  &:hover, &:focus {
    background: lighten($primary-color-2, 4);
  }

  [disabled]:hover, &:active, &:active:focus, &:hover:active {
    background: darken($primary-color-2, 14);
  }
}

.btn-primary-3 {
  background: $primary-color-3;
  color: #fff;

  i {
    margin-right: 7px;
  }
  @media (max-width: $screen-sm-max) {
    i {
      margin-right: 2px;
    }
  }

  &:hover, &:focus {
    background: lighten($primary-color-3, 4);
    color: #fff;
  }

  [disabled]:hover, &:active, &:active:focus, &:hover:active {
    background: darken($primary-color-3, 14);
  }
}

.btn-action {
  background: #3F3F3F;
  padding: 5px 6px;
  text-align: center;
  border: 0;
  border-radius: 2px;
  color: inherit;

  i {
    font-size: 14px;
    transition: color 0.1s linear;
  }

  .btn-action__content {
    padding: 0px 4px;
    display: inline-block;
    text-transform: uppercase;
  }

  .btn-action_content__text {
    padding-left: 4px;
  }

  &:hover {
    background: #4e4e4e;
    color: #c6c6c6;
  }
}

.btn.btn-sm {
  padding: 5px 10px;
  font-size: 11px;
}

.btn-action--liked {
  background: #3F3F3F;
  i { color: $love-color !important; }
}

.btn-action--follow {
  background: #2F2F2F;
  border: solid 0.5px #7A7A7A;
  height: 30px;
  padding: 5px 15px;
  &:hover {
    color: #7A7A7A;
  }
}

.btn-action--followed {
  background: transparent;
  border: solid 0.5px #7A7A7A;
  &:hover{
    background: transparent;
    color: #7A7A7A;
    .btn-action__content {
      display: none;
    }
    &:before {
      content: "Unfollow";
      text-transform: uppercase;
    }
  }
}

.btn-content-spinner {
  line-height: 23px;
  margin-top: -2px;
  display: inline-block;
}

.btn-copy--gray {
  width: 50px;
  background: #dadada;
  color: #5a5a5a;
  border: 1px solid #5a5a5a;
  line-height: 1.4rem;
  &:hover {
    background: #6b6b6b;
    color: #FFFFFF;
  }
}

.fv-btn--sm {
  min-width: 160px;
}

button, .btn {
  &:focus {
    outline: none;
  }
}

.btn-locked {
  background: white;
  border: black;
  color: black;
}
