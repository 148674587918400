@media (max-width: $screen-sm-min) {
  .track-selector {
    .artist-selector__icon {
      margin-top: 34px !important;
    }
    label.visible-xs-inline-block {
      margin-top: 10px;
    }
  }

  .artist-selector__item {
    margin-top: 20px;
  }
}

