.release-purchase-item {
  img {
    width: 25px;
    border-radius: 20px;
  }

  .pull-left {
    margin-top: 4px;
  }

  .align-top {
    align-self: flex-start;
  }

  &__name {
    font-weight: bold;
    font-size: 1.1em;
    text-transform: uppercase;
    vertical-align: middle;
    margin-left: 4px;
    letter-spacing: 0.4px;
    text-align: left;
  }

  &__cd-icon.fv.fv-cd {
    height: 1.4em;
    width: 1.4em;
    opacity: 1;
    color: white;
    vertical-align: middle;
  }
}

.release-purchase-item__top {
  margin-top: 10px;
  background: $table-row-background-variant-1;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  div {
    padding: unset;
  }
}

.release-purchase-item__top .release-purchase-item__currency {
  padding-right: 2rem;
}

.release-purchase-item__bottom {
  // background: $table-row-background-variant-1-hover;
  background: $table-row-background-variant-1;
  padding: 15px 20px;
  border-top: 2px solid $body-background-color;
}

input.merch-include-digital-download-input {
  margin-right: 5px;
  margin-top: 24px;
}

.release-purchase-item__cd-icon-wrapper {
  margin-right: 20px;
  margin-bottom: 3px;
}

.release-purchase-item__digital-download {
  font-size: 13px;
  font-weight: 200;
  text-transform: none;
  text-align: left;
  margin-left: 4px;
  color: #999;
}

.release-purchase-item__price {
  margin-right: 20px;
}

@media (max-width: $screen-sm-max) {
  .release-purchase-item__cd-icon-wrapper {
    margin-right: 12px;
    margin-bottom: 3px;
    i.fv.release-purchase-item__cd-icon {
      height: 0.9em;
      width: 0.9em;
    }
  }
  .release-purchase-item {
    &__top {
      padding: 8px 12px;
    }
    &__bottom {
      padding: 6px 12px;
      font-size: 12px;
      color: #808080;
      text-transform: capitalize;
    }
    &__name {
      text-transform: capitalize;
      font-weight: 300;
      font-size: 1em;
    }
    &__buy .purchase-btn {
      padding: 5px 10px;
      font-size: 17px;
    }
    &__price {
      margin-right: 0;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .release-purchase-item__price {
    font-size: 18px;
  }
}
