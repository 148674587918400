.offset-top {
  margin-top: 1em;
}

.half-offset-top {
  margin-top: 0.5em;
}

.offset-left {
  margin-left: 1em;
}

.half-offset-left {
  margin-left: 0.5em;
}

.offset-right {
  margin-right: 1em;
}

.double-offset-top {
  margin-top: 2em;
}

.double-offset-bottom {
  margin-bottom: 2em;
}

.double-offset-left {
  margin-left: 2em;
}

.offset-bottom {
  margin-bottom: 1em;
}

.offset-bottom-double {
  margin-bottom: 2em;
}

a.dashed-link {
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
  }
}

.collection-title {
  color: #797979;
  font-size: 1.1em;
  letter-spacing: 0.005em;
  text-transform: uppercase;

  &__release-date {
    @extend .label;
    font-size: 0.85em;
    font-weight: normal;
    text-transform: none;
    padding: 4px 10px;
    color: #8a8a8a;
  }
}

.resource-actions-row {
  .resource-actions-row-item {
    margin-right: 5px;
    float: left;
  }
}

.ember-basic-dropdown-content.fv-date-dropdown {
  width: 260px !important;
  background-color: #212121;
  border-radius: 2px;
  &.ember-basic-dropdown-content--below {
    transform: translateY(7px);
  }
  &.ember-basic-dropdown-content--above {
    transform: translateY(-7px);
  }
  .ember-power-calendar {
    padding: 10px 8px;
  }
}

.fv-section-header {
  border-bottom: 1px solid $separator-color;
  margin-bottom: 20px;
  padding-bottom: 2px;

  .fv-section-header-title {
    text-transform: uppercase;
    font-size: 3rem;
  }

  .fv-section-header-actions {
    &__delete-track-btn {
      margin-right: 5px;
    }
  }

  .fv-section-breadcrumbs {
    margin-top: 5px;
    margin-bottom: 5px;

    i {
      margin-left: 5px;
      margin-right: 5px;
    }
    a {
      color: $body-color;
    }
  }

  &.fv-section-title {
    text-transform: uppercase;
    letter-spacing: 0.3px;
    padding-bottom: 0.5rem;
    margin-bottom: 25px;
  }
}

.inline-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

.with-space-for-player {
  margin-bottom: 50px;
}

.fv-separator-main {
  width: 100%;
  border-bottom: 1px solid $separator-color;
  height: 20px;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-text-transform {
  text-transform: none;
}

.dimmed {
  opacity: 0.35;
}

.increase {
  color: $increase-color;
}

.decrease {
  color: $decrease-color;
}

.stagnant {
  color: gray;

  i {
    font-size: 0.7em;
  }
}

.image-thumbnail {
  width: 30px;
  border-radius: 15px;
  margin-right: 5px;
}

.overlay-shortener-area {
  overflow: hidden;
  max-height: 2000px;
  position: relative;
}

.overlay-shortener-area p:last-of-type {
  margin-bottom: 0;
}

.overlay-shortener-area--animatable {
  transition:0.3s ease-in-out;
}

.overlay-shortener-area--collapsed {
  max-height: 225px;
}

.shortener-overlay {
  height: 75px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: linear-gradient(transparent, rgba(50, 50, 50, 0) 34.45%, $body-background-color 97.48%);
}

.title--without-margin-bottom {
  margin-bottom: 0;
}

.full-width {
  width: 100% !important;
}

.border-top__thin {
  border-top: 1px solid #2F2F2F !important;
}

.hidden {
  display: none;
}

.blurred-element {
  filter: blur(10px);

  -webkit-filter: blur(10px);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
