.release-left-bar__label {
  margin-top: 20px;
  @media (min-width: $screen-sm-min) {
    margin-top: 13px;
  }

  .release-header-label-bio {
    color: #818181;
    margin-top: 4px;
    margin-bottom: 4px;
    word-wrap: break-word;
  }

  a.fv-link--blue {
    color: #6279ca;
  }
}

.release-left-bar__description {
  margin-top: 20px;
}
