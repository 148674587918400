.shipping-by-weight-prices-container {
  $cell-padding: 2px 5px;
  &__th {
    text-transform: uppercase;
    color: $secondary-text-color;
    padding: $cell-padding
  }

  &__th, &__td {
    &.with-indent {
      padding-left: 30px
    }
  }

  &__td {
    padding: $cell-padding;
    vertical-align: top;
    .form-group {
      margin-bottom: 0;
      width: 80px
    }
    &.text {
      padding-top: 10px
    }
    &.index {
      color: $secondary-text-color;
    }
    &.min-weight {
      .help-block {
        margin-left: -40px;
      }
    }
    &.min-weight, &.cost {
      .help-block {
        width: 180px
      }
    }
  }

  &__add-price-btn {
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    color: $body-color;

    &:hover {
      color: $body-color;
    }
  }

  &__remove-btn {
    color: $secondary-text-color;
    cursor: pointer;
    &:hover {
      color: $body-color;
    }
  }
}
