.overlay-actions {
  background: $body-background-color;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .fa {
    color: #888;
  }
}

.overlay-actions__close {
  margin: 15px 14px 25px;
  text-align: right;
  .btn-close {
    padding: 10px;
  }
  .fa {
    vertical-align: bottom;
  }
}

.fa-times-thin:before {
  content: '\00d7';
}

.overlay-actions__content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 60px);
  .record-title {
    text-align: center;
    line-height: normal;
    padding-top: 2rem;
    .record-artist-name {
      font-size: 12px;
      color: $secondary-text-color;
      a {
        color: $secondary-text-color;
      }
    }
  }
}

.overlay-actions__list {
  line-height: 47px;
  font-size: 16px;
  text-align: left;

  ul {
    list-style: none;
    padding: 10px 0;
    li {
      color: $body-color !important;
    }
    .fa {
      margin: 0 30px;
      font-size: 18px;
      width: 18px;
      text-align: center;
    }
  }
}

.record-image {
  text-align: center;
  min-height: 132px;
  img {
    width: 35%;
    margin: 0 auto;
    display: block;
  }
  img:before,
  .fa {
    content: "\f001";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 7rem;
    background-color: #5a5a5a;
    color: $body-color;
    width: 140px;
    height: 140px;
    padding-top: 15px;
  }
  .fa {
    padding-top: 30px;
  }
}

.record-name {
  color: $body-color
}

.overlay-actions__cancel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #141414;
  border-top: 1px solid #333;
  text-align: center;
  span {
    color: $body-color !important;
    display: block;
    font-size: 15px;
    line-height: 60px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .overlay-actions {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}
