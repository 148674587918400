.static-page-container {
  display: grid;
  margin-bottom: 10rem;
  grid-template-rows: 80px auto;
  grid-template-areas: "header"
                       "body";

  @media (max-width: $screen-md-min){
    grid-row-gap: 10px;
    grid-template-rows: 20px auto;
  }

  .social-links {
    ul li {
      padding-right: 5px;
      vertical-align: top;
      .fa {
        font-size: 1.75rem;
      }
    }
  }
}

.static-page-header {
  display: grid;
  justify-content: center;
  grid-area: header;
  grid-template-columns: 10% 55%;
  grid-template-areas: "date title";
  @media (max-width: $screen-md-min){
    grid-template-columns: 90%;
    grid-template-areas: "date"
                         "title";
    grid-auto-flow: row;
    grid-row-start: 2;
  }
}

.static-page-date {
  grid-area: date;
  align-self: self-end;
  text-transform: uppercase;
  color: $secondary-text-color;
  font-size: 13px;
  padding-bottom: 25px;
}

.static-page-image {
  width: 100%;
  height: 100%;
}

.static-page-title {
  grid-area: title;
  font-weight: 600;
  font-size: 3.5rem;
  align-self: self-end;
  padding-bottom: 25px;
  @media (min-width: $screen-md-min){
    line-height: 3rem;
  }
}

.static-page-body {
  display: grid;
  justify-content: center;
  grid-area: body;
  grid-template-columns: 10% 55%;
  grid-template-areas: "links content";
  @media (max-width: $screen-md-min){
    grid-template-columns: 90%;
    grid-row-start: 3;
    grid-template-areas: "links"
                         "content";
  }
}

.static-page-links {
  margin-top: 5px;
  @media (max-width: $screen-md-min){
    padding-bottom: 5px;
  }
  .twitter-share-button {
    margin-left: 5px;
    width: 20px;
    border: none;
    color: $body-background-color;
    background-color: #777;
    .twitter-div {
      vertical-align: super;
    }
    .fa {
      line-height: 20px;
      font-size: 16px;
    }
  }

  .fb-share-button {
    .fb-div {
      width: 22px;
    }
    vertical-align: top;
    font-size: 17px;
    border: none;
    margin-left: 0;
    background: transparent !important;
    color: #777;
  }
}

.static-page-content {
  grid-area: content;
  font-size: 1.75rem;
  line-height: 2.5rem;
  // text-align: justify;
}
