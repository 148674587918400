// Spacing

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-8 {
  margin: 2rem;
}

.m-12 {
  margin: 3rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mr-0 {
  margin-bottom: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-20 {
  margin-right: 5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

//font-size

.font-sm {
  font-size: 16px;
}

.divTable{
  //display: grid;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  .divTableHead .divTableRow .divTableHeading {
    border-bottom: none;
    .lt-sort-icon {
      width: 9px;
      display: inline-block;
    }
  }

  .divTableBody .divTableRow {
    margin-top: 2px;
    background: $table-row-background;

    &:hover {
      background: $table-row-background-hover;
    }
  }

  .divTableBody .divTableRow .divTableCell {
    vertical-align: inherit;
    border: none;
  }

  .order-number-column {
    width: 10%;
  }

  .table-cell-link {
    color: #f0f0f0;

    &:hover {
      color: #fff;
    }
  }

  .btn-table-action {
    padding: 3px 7px;
    color: inherit;
  }
}

.divTableRow {
  display: grid;
  height: 100%;
  @media (min-width: $screen-md-min) {
    grid-gap: 16px;
  }
  grid-template-columns: minmax(100px, 1fr) 50fr 7fr 12fr minmax(80px, 1fr)
}
.divTableCell, .divTableHead {
  padding: 8px;
}
.divTableCell {
  display:flex;
  align-items:center;
}
.divTableHeading {
  font-weight: bold;
}
.divTableFoot {
  font-weight: bold;
}
.divTableBody {
}
