.downloadable-list {
  padding-top: 10px;
  display: table;
  width: 100%;
  max-width: 100%;
}

.downloadable-list__item {
  .tracks-table-row {
    background: $table-row-background-hover;
  }
}
