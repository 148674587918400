.track-large {
  margin-top: 3.5em;
  margin-bottom: 1.5em;

  &:first-of-type {
    margin-top: 0.5em;
  }
}

.track-large__heading {
  font-size: 1.2em;
  margin-top: -0.3em;
  margin-bottom: 1em;

  .track-show-link {
    font-size: 1.2em;
    letter-spacing: 0.01em;
    color: $color-track-header;
  }

  a {
    color: $color-track-header;
    font-weight: 600;
  }
}

.track-large__bottom {
  padding-top: 1.5rem;
  overflow: auto;
}

.tracks-table {
  display: table;
}

.player-track-stats {
  color: #6b6b6b;
  font-size: 1em;
  > div {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.liked-profile {
  padding: 15px 0;
  height: 75px;
  width: 100%;
  > div {
    display: inline-block;
    vertical-align: middle;
  }
}

.liked-profile__image {
  width: 10%;

  img {
    width: 45px;
    height: 45px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max){
    width: 20%;
  }

  @media (max-width: $screen-sm-min){
    width: 15%;
  }

}

.liked-profile__type {
  font-size: 12px;
}

.liked-profile__details {
  font-size: 16px;
  color: #DADADA;
  padding-left: 14px;
  width: 50%;

  a.fv-link {
    color: #DADADA;
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: $screen-md-max){
    width: 40%;
  }
}

.track-likes-widget {
  .fv-link {
    cursor: pointer;
  }
}


.tracks-table-row.tracks-table-row--extra {
  font-size: 13px;
  line-height: 21px;
  padding: 10px 5%;
  margin-top: -1px;
}

.tracks-table-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  line-height: $release-track-table-row-height;
  min-height: $release-track-table-row-height;
  background: $table-row-background-variant-1;
  margin-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;

  @media (max-width: $screen-sm-min) {
    width: 100%;
    table-layout: fixed;
  }


  &:hover,
  &.active {
    background: $table-row-background-variant-1-hover;
  }

  &.active {

    &.tracks-table-row--extra {
      margin-bottom: 2px;
    }

    .main-control-button {
      background-color: $active-play-button-color;
    }
  }

  .ellipsis-animation {
    span {
      font-size: 14px;
    }

    margin-top: -1px;
  }

  .main-control-button {
    outline: none;
    background-color: $table-modal-row-background-hover;
    border-radius: 50%;
    margin-right: 7px;
    border: none;
    padding: 5px;
    height: $release-track-table-button-height;
    width:  $release-track-table-button-height;

    .icon-play,
    .icon-pause {
      display: block;
    }
  }

  .player-wrapper {
    margin-top: 9px;

    .player-play-section {
      margin-left: 0;
    }
  }

  > span {
    float: left;
  }

  $width-quantum: 5%;

  @mixin quantized-width($value: 1) {
    width: $width-quantum * $value;
  }

  .track-row-player-control-section {
    @include quantized-width;

    @media (max-width: $screen-sm-min) {
      @include quantized-width(2);
      padding-left: 5px;
    }
  }

  .track-row-index {
    @include quantized-width;
    min-width: 10px;
  }

  .track-row-name {
    @include quantized-width(5);
    @media (max-width: 800px) {
      @include quantized-width(4);
    }
    @media (max-width: $screen-sm-min) {
      @include quantized-width(7);
    }

    button {
      text-align: left;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 0;
    }

    .fv-link {
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }
  }

  .track-row-progress {
    @include quantized-width(9);
    position: relative;
    float: left;
    @media (max-width: 990px) {
      @include quantized-width(8);
    }

    @media (max-width: $screen-sm-min) {
      display: none;
    }
  }

  .track-row-time {
    @include quantized-width(1);
    text-align: right;
    padding: 10px 5px;
    @media (max-width: $screen-sm-min) {
      float: right;
      margin-right: 30px;
    }
  }
  .track-time-and-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 2em;
    @media (max-width: $screen-sm-min) {
      gap: 1em !important;
    }
    @media (max-width: $screen-size-mobileS) {
        flex-wrap: wrap;
      }
  }

  @media (min-width: $screen-size-medium) and (max-width: $screen-size-large) {
    .track-time-and-buttons {
      gap: 0 !important;
    }
    .main-control-button {
      height: 26px;
      width: 26px;
    }
  }

  @media (max-width: $screen-xs-min) {
    .track-time-and-buttons {
      gap: 0 !important;
    }
    .main-control-button {
      height:$screen-xs-main-track-table-button-height;
      width: $screen-xs-main-track-table-button-height;
    }
  }

  @media (max-width: $screen-size-mobileS) {
    .main-control-button {
      height: $screen-mobileS-main-track-table-button-height;
      width: $screen-mobileS-main-track-table-button-height;
    }
  }

  .action-btns {
    display: flex;
    justify-content: flex-start;
    min-width: $action-buttons-width;
  }

  .action-btns {
    margin-left: 20px;

    button[disabled] {
      cursor: not-allowed;
    }
  }
}

.release-tracks-table {
  .order-with-actions-column {
    width: 12%;

    min-width: 120px;

    .order-number {
      min-width: 10px;
      display: inline-block;
    }
    .order-actions {
      margin-left: 5px;
    }
  }

  .track-name-column {
    img {
      margin-right: 10px;
    }
  }

  .track-actions-column {
    text-align: right;
  }
}

.track-row__btn {
  line-height: 18px;
  color: #aaaaaa;
  box-shadow: none;
  border: 0px;
  border-radius: 2px;
  padding: 5px 5px;
  text-align: center;
  i {
    text-align: center;
  }
}

.track-row__btn--buy,
.track-row__btn--like,
.track-row__btn--share,
.track-row__btn--details {
  background-color: transparent;
  border-color: #515151;
}

.track-row__btn--download {
  background: #2BB39A;
  &.btn-action:hover,
  &.btn-action:active,
  &.btn-action:focus {
    background: #38CFB3;
  }
}

.track-row__dropdown {
  .track-row__btn.dropdown-toggle {
    background: #2BB39A;
    color: #FFFFFF;
    &:hover, &:active, &:focus {
      background: #38CFB3;
      color: #FFFFFF;
    }
  }
}

.track-download__checkbox {
  padding-bottom: 8px;
  margin-left: 5px;
}

.track-download__options {
  padding-left: 5px;
  list-style: none;
}

.track-download__option {
  cursor: pointer;
  padding-top: 5px;
  width: 100%;
  color: #3F3F3F;
  text-decoration: none;
  &:hover {
    color: #6b6b6b;
  }
}
.track-row__dropdown-menu {
  padding-bottom: 5px;
  padding-top: 5px;
  min-width: 0;
  right: 0;
  left: unset;
}

.track-list-table {
  .track-order-column {
    width: 3%;
  }

  .track-title-column {
    width: 45%;
  }

  .track-image-column {
    width: 30px;
  }

  tr {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background: $table-modal-row-background-selected !important;
    }
  }
}

.track {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-areas: "track-sidebar track-content ";

  @media (max-width: $screen-sm-max) {
    grid-template-columns: 100%;
    grid-template-areas: "track-content"
                         "track-sidebar";
  }
}

.track__content {
  grid-area: track-content;
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-template-areas: "track-header"
                       "track-main-player"
                       "track-player";

  @media (max-width: 990px) {
    grid-template-rows: repeat(5, max-content);
    grid-template-columns: 100%;
    grid-template-areas: "track-image"
                         "track-main-player"
                         "track-header"
                         "track-description"
                         "track-player";
  }
}

.track__side-bar {
  grid-area: track-sidebar;
  padding-right: 20px;
  display: grid;
  grid-template-rows: repeat(5, max-content);
  grid-template-areas: "track-image"
                       "track-description"
                       "track-profile"
                       "track-artists"
                       "track-tags";
  @media (max-width: 990px) {
    grid-template-areas: "track-profile"
                         "track-artists"
                         "track-tags";
  }
}

.track__main-player {
  grid-area: track-main-player;
}

.track__artists {
  grid-area: track-artists
}

.track__image {
  grid-area: track-image;
  .fv-cover-image {
    @media (max-width: 990px) {
      width: 50%;
      padding: 50% 0 0;
      margin-bottom: 15px;
    }
  }
}

.track__description {
  grid-area: track-description;
  min-width: 0;
}

.track__profile {
  grid-area: track-profile;
}

.track__tags {
  grid-area: track-tags;
}

.track__header {
  grid-area: track-header;
  display: grid;
  grid-template-columns: 65% 35%;
  @media (max-width: $screen-sm-max) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    grid-column: 1 / span 8;
    display: block;
    padding-bottom: 20px;
    padding-right: unset;
  }
}

.track__player {
  grid-area: track-player;
  padding-right: 25px;
  @media (max-width: $screen-sm-max) {
    grid-template-columns: 100%;
    padding-right: unset;
  }
}

.track__name {
  margin-top: -6px; // Compensate for line height to make the top of text horizontal with the button and cover image
}

.track__action {
  margin-left: auto;
}

.track-artist-name {
  a {
    color: $body-color;
    font-weight: 600;
  }
}

.track__name {
  margin-top: -6px; // Compensate for line height to make the top of text horizontal with the button and cover image
}

.track__action {
  margin-left: auto;
}
