.player-wrapper {
  margin-top: 10px;

  wave {
    cursor: pointer;
    transition: all 0.08s;
  }

  .player-control-section {
    position: absolute;
    vertical-align: top;
    margin-top: 5px;
    margin-right: 8px;
    z-index: 4;
  }

  .player-play-section {
    margin-left: 60px;
  }

  .player-track-info-badge {
    padding: 0px 4px;
    border: 1px solid #1d1d1d;
    background: #000;
    border-radius: 2px;
    font-size: 12px;
    position: absolute;
    margin-top: -26px;
    z-index: 3;
  }

  .player-track-length {
    position: relative;
    min-width: 28px;
    float: right;
  }

  .main-control-button {
    width: 5rem;
    height: 5rem;
    border-radius: 30px;
    background: $active-play-button-color;
    font-size: 2.3rem;
    border: none;
    outline: none;

    i {
      position: absolute;
      margin-top: -1.1rem;
      margin-left: -0.6rem;
    }

    .icon-pause {
      margin-left: -0.9rem;
    }

    .icon-loading {
      margin-left: -1rem;
      font-size: 2.1rem;
    }

    .ellipsis-animation {
      top: 0;
      position: absolute;
      right: 12px;
    }
  }
}

.player-master {
  padding-top: 0;
}

.player-wrapper--master {
  color: $body-color;
  margin: 0;
  display: grid;
  grid-template-areas: "track-image track-info control-section track-time play-section track-duration action-btns volume-container";
  grid-template-columns: 4% 15% 12% 5% 45% 5% 10% 4%;

  @media (max-width: $screen-sm-min) {
    height: 75px;
    grid-template-areas: "track-image control-section action-btns";
    grid-template-columns: 10% 45% 45%;
  }


  .master-player-button {
    border: 0;
    background: transparent;
    width: 40px;
    height: 50px;
    outline: none;
    padding-left: 15px;
    padding-right: 14px;
  }

  .volume-control-button {
    float: left;
  }

  .master-control-button {
    .ellipsis-animation {
      margin-top: -7px;
      margin-left: -1px;
    }

    .ellipsis-animation span {
      font-size: 20px;
    }

    &:hover {
      color: #fff;
    }
  }

  .player-wrapper {
    padding: 20px;
  }

  .player-track-image {
    grid-area: track-image;
    padding-top: 10px;

    @media (max-width: $screen-sm-min) {
      margin-left: unset;
    }
  }

  .player-control-section {
    grid-area: control-section;
    margin: 0;
    float: left;
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }

  .player-play-section {
    grid-area: play-section;
    margin: 0;
    float: left;
    padding-top: 2px;
    margin-left: 5px;
  }

  .separator {
    height: $footer-height;
    border-right: 1px solid #555555;
    float: left;
    margin-left: 2px;
    margin-right: 2px;
  }

  .player-track-info {
    grid-area: track-info;
    float: left;
    padding-top: 16px;
    font-size: 13px;
    opacity: 0.8;

    .fv-link {
      color: #f5f5f5;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .player-track-time {
    grid-area: track-time;
    float: left;
    padding-top: 16px;
    min-width: 40px;
    font-size: 14px;
    text-align: center;
  }

  .player-track-thumb {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 7px;
    background: #232526;
    background: -webkit-linear-gradient(to right, #414345, #232526);
    background: linear-gradient(to right, #414345, #232526);
    vertical-align: middle;
    display: inline-block;
  }

  .player-track-duration {
    grid-area: track-duration;
    padding-top: 16px;
    font-size: 14px;
    float: left;
    text-align: center;
  }

  .player-volume-container {
    grid-area: volume-container;
    float: right;
    margin-right: -2px;
  }

  .player-action-btns {
    grid-area: action-btns;
    display: inline-block;
    padding-top: 12px;
    text-align: center;

    .track-row__btn {
      color: #DADADA;
      margin-right: 5px;
    }

    .track-row__btn--buy {
      margin-right: 0px;
    }

    .action-btns {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .player-volume {
    float: left;
    width: 40px;

    .fv-vertical-slider {
      border: 1px solid #555555;
      width: 46px;
      margin-left: -3px;
      border-bottom: none;
      background: $footer-color;
      margin-top: -137px;
      padding-left: 17px;
      padding-top: 16px;
    }

    .noUi-target {
      height: 120px;
    }

    .noUi-vertical {
      width: 9px;
    }

    .noUi-handle {
      width: 17px;
      height: 17px;
      top: -8px;
      right: -5px;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }
}

.wave-wrapper {
  position: relative;
  overflow: hidden;
  wave {
    top: 15px;
  }
}

.footer-player-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  background: #141414;
  color: #000;
  z-index: 1000;
}
