.shopping-cart-table {
  .quantity-column {
    input {
      height: 31px;
      padding-left: 7px;
      padding-right: 7px;
      max-width: 60px;
    }
  }

  .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
  }

  .fv-link {
    color: #d7d7d7;

    &:hover {
      color: #d7d7d7;
    }
  }
}

.cart-payment-amounts-table {
  td:first-child {
    width: 75px;
  }

  td:nth-child(2) {
    width: 75px;
  }

  td:nth-child(3) {
    width: 155px;
  }

  td {
    line-height: 25px;
  }

  .shipping-selector-column {
    .shipping-selector-cell {
      display: inline-flex;
      align-items: baseline;
    }

    span {
      margin-right: 5px;
    }

    .ember-basic-dropdown {
      display: inline-block;
      width: 130px;
    }
  }
}

.btn-navbar-icon {
  padding: 0 10px 0 5px;
  color: #808080;
  background: transparent !important;
  font-size: 25px;
  position: relative;
  &:hover, &:active, &:focus {
    color: #D8D8D8 !important;
  }
  &.dropdown-toggle {
    .open > &,
    &:hover, &:active, &:focus {
      color: #D8D8D8 !important;
    }
  }
  @media (max-width: $screen-sm-min) {
    padding: unset;
    top: 2px;
  }
}
