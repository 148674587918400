.tracks__table {
  table thead tr th {
    line-height: 4rem;
    font-weight: 500;
    color: #a5a5a5 !important;
  }

  table tbody tr {
    background: $body-background-color;
    border-bottom: 1px solid #333333;
    margin: 0;
    &:last-child {
      border: none;
    }
    &:hover {
      background: #222222;
    }
    td {
      padding: 6px;
    }
    a {
      color: #787878;
    }

    &.active {
      background: #222222;
      .main-control-button {
        color: #1995fc;
      }
    }
  }

  table thead tr th,
  table tbody tr td {
    color: #787878;
    font-weight: 300;
    padding-left: 6px;
    padding-right: 6px;

    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 12px;
    }

    @media (max-width: $screen-sm-max) {
      &:first-child {
        padding-left: 12px;
      }
      &:last-child {
        padding-right: 4px;
      }
    }
  }

  .main-control-button {
    outline: none;
    background: transparent;
    border: none;
    padding: 0;

    .ellipsis-animation {
      margin-top: -20px;
      margin-left: -4px;
      padding-right: 5px;
      span {
        font-size: 26px;
      }
    }

    .icon-play,
    .icon-pause {
      display: block;
    }
  }

  .fv-body-color,
  .table-row-index,
  .main-control-button {
    color: $body-color;
  }
}
