.country-with-flag {
  display: inline-block;
  margin-right: 5px;
  &__flag {
    display: inline-block;
    padding-top: 2px;
    vertical-align: middle;
  }
  &__name {
    margin-left: 2px;
    display: inline-block;
  }
}
