.artist-selector__item {
  margin-bottom: 10px;
}

.artist-selector__label > div,
.artist-selector__item > div {
  display: inline-block;
  vertical-align: middle;
}

.artist-selector__remixer {
  label {
    margin: 0;
    margin-top: 7px;
    &:before {
      top: 2px !important;
    }
    &:after {
      top: 4.5px !important;
    }
  }
}

.artist-selector__item {
  .artist-select-trigger {
    &:disabled {
      background-color: transparent;
    }
  }
}

.artist-selector__add {
  i {
    margin-right: 5px;
  }

  .btn-action__artist {
    margin-top: unset;
    width: 150px;
    border: none;
    text-align: left;
    padding: 0;
    &:hover {
      border: none;
      box-shadow: none;
      text-decoration: underline;
    }

    &::after {
      content: none;
    }
  }
}

.btn-action__artist {
  margin-top: 7px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: transparent;
  outline: none;
  border: white 0.5px solid;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 2px; // adjust as needed
    margin: -1px 0 0 -4px; // (height/-2)px 0 0 (width/-2)px
    background: #fff;
  }

  &:hover {
    border: 1px solid #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
    outline: 0;
  }
}

.artist-selector__icon {
  vertical-align: middle;
  text-align: center;
  &.has-margin-top {
    margin-top: 24px;
  }
  .btn-action__artist:hover {
    box-shadow: none;
    border-color: #31B49C;
    &:after {
      background: #31B49C;
    }
  }
}
