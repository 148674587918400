.qq-uploader {
  padding: 0;
  min-height: inherit;
}

.resource-uploader {
  background: transparent;
  text-align: center;
  border: none;

  position: inherit;

  .qq-upload-list-selector {
    li {
      background: transparent;
      color: inherit;
      border: none;
    }
  }

  .qq-upload-status-text {
    display: none;
  }

  .qq-progress-bar-container-selector {
    background: #2c2c2c;
    border-radius: 5px;
  }

  .qq-progress-bar {
    margin-bottom: 15px;
    background: $primary-color;
    transition: width .6s ease;
  }

  .qq-btn {
    text-shadow: none;
    box-shadow: none;
    border: none;
    color: #000;
  }

  .qq-btn.qq-upload-cancel, .qq-btn.qq-upload-pause {
    background: #be8b8b;
  }

  .qq-btn.qq-upload-pause {
    background: #c8c8c8;
  }

  .qq-btn.qq-upload-continue {
    border: none;
    background: $primary-color;
    border: none;
    color: #000;
  }

  .qq-upload-drop-area {
    background: transparent;
    border: none;
  }
}

.upload-button {
  width: auto;
  display: inline-block;
  float: none;
  margin-top: 25px;
}

.multi-upload-button {
  width: auto;
  display: inline-block;
  float: none;
  vertical-align: middle;
}

.save-button-wrapper {
  width: 100%;
  background: #252525;
  padding-bottom: 10px;
}

.multiupload-inputs {
  .ember-power-select-placeholder, .ember-power-select-trigger, .ember-power-select-option, .ember-power-select-search-input, .ember-power-select-search, .form-control {
    background: #1a1a1a;
  }
}

.track-uploader-wrapper {
  border-radius: 4px;
  padding: 40px 10px;
  background: #252525;

  &.track-status-uploading, &.track-status-processing {
    .upload-button, .track-upload-prompt {
      display: none;
    }
  }

  &__track-name {
    font-weight: 600;
    font-size: 1.4em;
    letter-spacing: 0.01em;
    text-align: center;
  }
}

.csv-uploader-wrapper {
  border-radius: 4px;
  height: 200px;
  padding: 40px 10px;
  background: #252525;
}

.fv-modal {
  .track-status-main {
    font-size: 3rem;
  }

  .track-uploader-wrapper {
    background: #313131;
    padding: 30px 10px;
  }
}

.image-uploader {
  margin-top: 100px;
  background: transparent;
  text-align: center;
  border: none;
}

.track-status-main {
  text-align: center;
  font-size: 3rem;
}

.track-substatus-main {
  font-size: 2rem;
  padding-top: 20px;
  margin-bottom: 10px;
}

.processing-progress {
  background: #2c2c2c;
  height: 15px;

  .progress-bar {
    border-radius: 6px;
    background-color: $primary-color;
  }
}

.image-uploader-wrapper {
  &.is-uploading {
    .min-limit-message {
      display: none;
    }

    .upload-button {
      visibility: hidden;
    }

    .qq-upload-list {
      margin-top: -25px;
    }
  }
}

.has-error .image-uploader-area  {
  border: 1px solid $color-error;
}

.image-uploader-area {
  width: 280px;
  height: 280px;
  border-radius: 4px;
  background: #252525;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;

  &.image-present {
    .btn-upload-image-main {
      visibility: hidden;
    }

    .min-limit-message {
      display: none;
    }
  }
}

.upload-followers-subtitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: larger;
}

.followers-text {
  margin-top: 10px;
  margin-bottom: 30px;
}
.progress-primary {
  background-color: $primary-color !important;
}

.upload-info-text {
  font-size: 1.2em;
  margin-top: 10px;
  text-align: center;
}
.response-mt {
  margin-top: 2rem;
}

#upload-message {
  display: none;
}


.upload-status{
  font-size: 1.4em;
  margin-top: 20px;
  text-align: center;
}

.success {
  display: block;
  color: $increase-color;
}

.error {
  display: block;
  color: $love-color;
}

.dashed-border {
  border: 1px dashed #5c5c5c;
  border-radius: 4px;
  margin-top: 20px;
  height: 220px;
}
.flex {
  display: flex;
}

.upload-progress {
  display: none;
  margin: 15px 15px 0;
}
