.fv-release {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-areas:
    "release-left-bar release-content";
  @media (max-width: $screen-sm-min) {
    grid-template-columns: 100%;
    grid-template-areas:
      "release-left-bar"
      "release-content";
  }
}

.fv-release {
  .release-content {
    padding-right: unset;
  }

  .release-header {
    padding-right: unset;
  }

  .release-items {
    padding-right: unset;
  }
}

.release-widget {
  display: grid;
  grid-template-columns: 260px auto;
  grid-template-areas:
    "release-image release-header"
    "release-image release-main-track"
    "release-profile release-items";
  @media (max-width: 990px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      "release-image"
      "release-header"
      "release-main-track"
      "release-buy-buttons"
      "release-purchase-items"
      "release-items"
      "release-description"
      "release-profile"
      "release-tags";
  }
}


.release-purchase-items {
  grid-area: release-purchase-items;
}

.release-buy-buttons {
  grid-area: release-buy-buttons;
}

.release-image {
  grid-area: release-image;
}

.release-tags {
  grid-area: release-tags;
}

.release-widget .release-image {
  margin-right: 2.5rem;
  @media (max-width: 990px) {
    margin-right: 0px;
  }
}

.release-widget .release-image .fv-cover-image {
  @media (max-width: 990px) {
    width: 50%;
    padding: 50% 0 0;
    margin-bottom: 15px;
  }
}

.release-header {
  grid-area: release-header;
}

.release-content {
  display: block;
  grid-template-columns: 100%;
  grid-template-rows: max-content 150px max-content auto;
  grid-template-areas:
    "release-header"
    "release-main-track"
    "release-items"
    "release-purchase-items";
  overflow: hidden;
}

.release-main-track {
  grid-area: release-main-track;
}

.release-profile {
  grid-area: release-profile;
  margin: 15px 25px 15px 0px;
  display: flex;

  @media (min-width: 990px) {
    border-top: 1px solid #5a5a5a;
    padding-top: 10px;
  }

  .release-profile__image {
    padding-right: 10px;
  }

  .release-profile__name {
    font-weight: bold;
    a {
      color: #FFFFFF;
      text-decoration: none;
      &:hover, &:focus {
        color: #dadada;
      }
    }
  }
}

.release-items {
  grid-area: release-items;
}

.release-left-bar {
  grid-area: release-left-bar;
  padding-right: 25px;
  @media (max-width: $screen-sm-min) {
    padding-right: unset;
    padding-bottom: 25px;
  }
}

.release-content {
  grid-area: release-content;
}

.releases-top-bar {
  display: flex;
}

.releases-top-bar .sortable-select {
  @media (max-width: $screen-sm-min) {
    padding-right: 5px;
    right: 10px;
    left: unset;
    float: unset;
  }
}

.releases__sieve {
  margin-left: auto;
  margin-top: 5px;
  padding-right: 10px;
}

.releases__sieve > div {
  padding-right: 0;
}

.release-header-label {
  margin-top: 20px;
  @media (min-width: $screen-sm-min) {
    border-top: 1px solid #5a5a5a;
    margin-top: 13px;
  }

  .release-header-label-bio {
    color: #818181;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  a.fv-link--blue {
    color: #6279ca;
  }
}

.release-header__info {
  margin-top: 20px;
}

.release-top-buy-area {
  @media (max-width: 900px) {
    float: none !important;

    button {
      margin-top: 10px;
      width: 100%;
    }
  }

  &--xs {
    margin-bottom: 40px;
  }
}

.col-release-content {
  @media (min-width: 768px) {
    padding-left: 30px;
  }
}

.col-release-header-label {
  @media (min-width: $screen-sm-min) {
    padding-left: 0;
  }

  > a:hover {
    text-decoration: none;
  }

  h1 {
    font-weight: 700;
    font-size: 16px;
    color: $body-color;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 5px;
  }
}

.tag-item {
  @media (min-width: $screen-sm-min) {
    padding-left: 0;
  }

  a {
    font-size: 16px;
    color: $body-color;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 5px;
  }
}

.release-title {
  display: flex;
  margin-bottom: 10px;

  h2 {
    @media (max-width: $screen-sm-min) {
      font-size: 2em;
    }
  }

  &__release-name {
    font-size: 3.6rem;
    margin-bottom: 0px;
  }

  a {
    color: $body-color;
    font-weight: 600;
  }
}

.release__action {
  margin-left: auto;
  display: flex;
}

.release__action-edit > a {
  font-weight: unset;
  color: #FFFFFF;
}

.listen-more__link {
  width: 250px;
  text-align: right;
}

.tags-selector {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.tags-selector__item {
  float: left;
  background: cornflowerblue;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  height: auto;
}

.tags-selector__item-selected {
  background: dodgerblue;
}

.tags-selector__item a {
  display: block;
  color: white;
  text-align: center;
  padding: 1px 16px;
  text-decoration: none;
}

.tags-selector__item a:hover {
  background-color: lightskyblue;
  border-radius: 5px;
}

.locked-overlay
{
  z-index: 900;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
}

.locked-overlay-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.locked-overlay-image-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
