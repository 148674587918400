#overlay_spinner {
  background-color:rgba(0, 0, 0, 0.65);
  z-index:900;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: none;
}

.overlay-container {
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  text-align: center;

  .logo {
    width: 13em;
    display: inline-block;
  }

  .progress-messages {
    font-size: 18px;
    margin-top: 15px;
    width: 500px;
  }
}

.overlay-container-centered {
  margin: auto;
}
