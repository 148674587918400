.previous-next-page-button {
  font-size: 16px !important;
  margin: 0 !important;
  line-height: 24px !important;

  &:focus, &:hover {
    border: 0 !important;
  }
}

.pagination-ellipsis {
  float: left;
  margin-right: 3px;
}

.pagination-nav {
  &.no-margins {
    .pagination {
      margin: 0;
    }
  }
  .pagination {
    height: 20px;
  }
}
