.profile {
  display: grid;
  grid-template-columns: 24% 74%;
  grid-column-gap: 2%;
  grid-template-rows: max-content 50px 100px auto;
  grid-template-areas: "sub-menu sub-menu"
                       "side-bar profile-header"
                       "side-bar sub-nav"
                       "side-bar profile-overview";
  @media (max-width: $screen-sm-min) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, max-content) auto;
    grid-column-gap: unset;
    grid-template-areas:
        "sub-menu"
        "profile-header"
        "side-bar"
        "sub-nav"
        "profile-overview";
  }
}

.profile__sub-nav {
  grid-area: sub-nav;

  .records__count {
    margin-left: 3px;
    font-size: 12px;
  }

  .sub-menu__link {
    padding: 0 8px;
    color: #888888;
  }

  @media (max-width: $screen-sm-max) {
    .sub-menu {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 0;
        height: 1px;
      }

      .sub-menu__nav {
        padding: 0 15px 0 15px;
        padding-left: unset;
      }

      .sub-menu__link.active {
        padding-bottom: 8px;
      }
    }
  }
}

.profile__sub-menu {
  grid-area: sub-menu;
}

.profile__cover {
  background-color: #232526;
  width: 100%;
  @media (max-width: $screen-sm-min) {
    width: 80%;
    margin: 0 10% auto;
  }
}
.profile__cover--no-image {
  background-image: linear-gradient(to right, #414345, #232526);
  padding: 100% 0 0;
}

.profile__cover--round {
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.profile__cover-placeholder {
  font-size: 38px;
}

.profile__cover-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profile__side-bar {
  grid-area: side-bar;
  @media (max-width: $screen-sm-max) {
    margin-top: unset;
  }
}

.profile__header {
  grid-area: profile-header;
  display: flex;
}

.profile__title {
  flex: 1 1 auto;
  margin-top: -6px; // Compensate for line height to make the top of text horizontal with the button and cover image
  text-transform: none;
  word-break: break-word;
}
.profile__action {
  flex: 0 0 auto;
}

.profile__info {
  margin-top: 15px;
  @media (max-width: $screen-sm-max) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.profile__overview {
  grid-area: profile-overview;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-areas: "profile-overview-filter"
                       "profile-overview-content";
}

.profile__overview-filter {
  grid-area: profile-overview-filter;
  padding-bottom: 15px;
  .filter-select {
    width: 50%;
  }
  .filter-select__dropdown {
    margin-left: 50%;
    @media (max-width: $screen-sm-min) {
      margin-left: unset;
    }
  }
}

.profile__overview-content {
  grid-area: profile-overview-content;
}

.profile__social-links {
  margin-top: 15px;
}
