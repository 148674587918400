.share-popover {
  color: #5a5a5a;
  background-color: #dadada;
  border-radius: 8px;
  padding: 0;
  max-width: 290px;
  width: 290px;
  cursor: default;

  input {
    border: none;
    width: 200px;
    height: 30px;
    padding: 0 10px;
    background: #fff;
    font-size: 13px;
    border-radius: 2px;
    margin-right: 5px;
    overflow: scroll;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
  }
}

.share-popover.top,
.share-popover.bottom {
  .arrow::after {
    border-top-color: #dadada;
    border-bottom-color: #dadada;
  }
}

.share-panel {
  display: inline;
  margin-left: 5px;
}

.share-popover__sn-buttons {
  margin-top: 5px;

  .fb-share-button,
  .twitter-share-button {
    padding: 3px 2px 2px 3px;
  }
}

.share-button {
  outline: none;
  div {
    outline: none;
    width: 15px;
  }
}
