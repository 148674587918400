.fv-box-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  grid-gap: 20px;
}
.fv-box-list--four {
  grid-template-columns: repeat(2, minmax(125px, 1fr));
  @media (min-width: $screen-sm-min) {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
}
.fv-box-list--only-cover {
  grid-template-columns: repeat(2, 80px);
  grid-template-rows: repeat(2, 80px);
  grid-gap: 10px;
}

.fv-box-list--latest {
  grid-template-columns: repeat(6, minmax(150px, 1fr));

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }

  @media (max-width: $screen-sm-min) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

.fv-box-list--large {
  @media (max-width: 990px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  .fv-box__info {
    padding: 10px 10px 6px 10px;
  }
}

.fv-box-list__item {
  position: relative;
  .action-btns {
    padding-left: 10px;
    .track-row__btn {
      margin-bottom: 10px;
    }
  }
}

.fv-box-list__placeholder {
  grid-column: 1 / -1; // Spans all columns
  padding: 100px 0;
  text-align: center;
}

.fv-box {
  position: relative;
  display: block;
  height: 100%;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 10px;
  background-color: #212121;
  transition: background-color 0.15s ease;
  &:focus,
  &:hover {
    background-color: lighten(#212121, 10%);
  }

  .action-btns {
    position: absolute;
    bottom: 0;
    width: 100%;
    .btn-action {
      i {
        font-size: 14px;
        color: #C6C6C6;
      }
      background-color: transparent;
      &:hover {
        background-color: lighten(#212121, 4);
      };
      font-size: 14px;
    }
  }
  .fv-box__bottom-btns {
    height: 38px;
  }
}

.fv-box__info {
  padding: 10px;
  color: #9e9e9e;
  font-size: 1.4rem;
}

.fv-box__info-title {
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #C6C6C6;
}

.fv-box__info-label {
  margin-top: 3px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fv-box__cover {
  position: relative;
  height: 0;
  padding: 100% 0 0; // Padding is relative to the width of the element creating a perfect square
}
.fv-box__image,
.fv-box__cover-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.fv-box__cover-placeholder {
  margin-top: 35%;
  font-size: 60px;
  text-align: center;
}
.fv-box-list--only-cover {
  .fv-box__cover-placeholder {
    font-size: 35px;
  }
}

.fv-box-list__action-item {
  position: absolute;
  top: 10px;
  right: 10px;
  .btn-action--follow {
    background: #333;
    border: none;
    &:hover {
      color: #7A7A7A;
      background: #3F3F3F;
    }
  }
  .btn-action--followed {
    background: #333;
    border: none;
    &:hover {
      background: #3F3F3F;
    }
  }
}

.fv-box {
  .svg-symbol {
    height: 18px;
    width: 15px;
  }
}

.fv-box-edit-link {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1px;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  color: #c5c5c5;
  background: #333;
  &:hover,
  &:focus {
    color: #fff;
  }
}

.fv-box-edit-link__icon {
  margin: 5px 0 0;
}

.fv-box__type {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 10px;
  padding-bottom: 5px;
}

.fv-box__private {
  text-align: right;
}
