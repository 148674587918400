.donate-widget__form {
  background: #252525;
  padding: 15px;
}

.donate-widget__currency-label {
  height: 40px;
  background: #2b2b2b;
  text-align: center;
  padding-top: 10px;
  width: 20%;
  display: inline-block;
  font-weight: bold;
}

.donate-widget__amount {
  width: 70%;
  vertical-align: top;
  padding-left: 5px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: unset;
  input {
    background-color: #2b2b2b;
    font-weight: bold;
    height: 40px;
  }
}

.donate-widget__input {
  margin-bottom: 15px;
  p {
    font-size: 14px;
    font-weight: bold;
  }
}

.donate-widget > p {
  font-size: 14px;
  font-weight: bold;
}
