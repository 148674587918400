.sortable-table-head {
  &__button {
    color: $body-color;
    background: transparent;
    border: 0px;
    padding: 0px;
    &:hover {
      color: darken($body-color, 20);
      outline: none;
    }
    &:visited, &:focus {
      color: $body-color;
      text-decoration: none;
      outline: none;
    }
  }
}
