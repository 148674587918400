.dropdown-checkout {
  .dropdown-menu {
    background: #252525;
    width: 380px;
    padding: 15px;
    left: -325px;
    @media (max-width: $screen-xs-max) {
      left: -272px;
      width: 315px;
    }
  }
}

.notification-item-count {
  background: #FF3E3E;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  padding: 1px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
  right: 0;
  top: -5px;
  z-index: 2;
  box-shadow: 0 0 15px 0 rgba(50, 50, 50, 0.55);
  @media (max-width: $screen-sm-min) {
    left: 12px;
    font-weight: bold;
    bottom: 22px;
  }
}

.payment-form {
  min-height: 116px;

  &.payment-form--loading {
    // border: 1px solid #dadada;
    // border-radius: 5px;
  }

  .braintree-loader__container {
    background: $body-background-color;
    border: 1px solid #dadada;
    border-radius: 5px;
  }

  .braintree-option {
    border-color: #7a7a7a;
  }

  input {
    color: white;
  }

  .braintree-sheet {
    background: $body-background-color;
  }

  .braintree-sheet__button--paypal {
    margin-top: 8px;
  }

  .braintree-placeholder {
    display: none;
  }

  .braintree-form {
    background: $body-background-color;
  }

  .braintree-form__field-group {
    .braintree-form__label {
      color: $body-color;
    }
  }

  .braintree-sheet__text {
    color: $body-color;
  }

  .braintree-dropin {
    font-family: inherit;
  }

  .braintree-heading {
    display: none;
  }

  .braintree-option {
    background: $body-background-color;
  }

  .braintree-option__label, .braintree-method__label {
    color: #fff;
  }

  .braintree-toggle {
    background: $body-background-color;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    border: none;

    &:hover {
      span {
        border-color: #fff;
      }
    }
  }

  .braintree-method {
    background: $body-background-color;
  }
}

.billing-shipping-address-selector {
  border: 1px solid gray;
  border-radius: 3px;
}

.billing-shipping-address-selector__item {
  padding: 18px;
  border-bottom: 1px solid gray;

  &:last-of-type {
    border: none;
  }

  &:hover {
    cursor: pointer;
  }

  .radio-item {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 10px;
    border: 2px solid gray;
    display: inline-block;
    vertical-align: sub;
  }

  .radio-item--active {
    background: $primary-color;
  }
}
