i.fv {
  display: inline-block;
  width: 56px;
  height: 60px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.7;
}

i.fv-logo {
  display: inline-block;
  width: 50px;
  height: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.7;
}

.fv-mobile-app-image {
  background-image: url("../assets/icons/fv-mobile-app-image.png");
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.store-badges-container {
  margin-top: 30px;
  width: 50px;
}

.app-store-badge {
  &:hover {
    background-color: #4e4e4e;
    opacity: 30%;
  }
  background-image: url("../assets/icons/app-store-badge.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  height: 40px;
  width: 135px;
}

.playstore-badge {
  &:hover {
    background-color: #4e4e4e;
    opacity: 30%;
  }
  background-image: url("../assets/icons/google-play-badge.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  height: 40px;
  width: 135px;
}

/* geometrical icons */

@mixin icon-mask-image($value) {
  background-color: #999;
  -webkit-mask-image: $value;
  mask-image: $value;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

i.fv-navbar-logo {
  @include icon-mask-image(url(/assets/icons/fv-logo-new-white.svg));
}

i.fv-vision {
  // background-image: url(/assets/icons/vision.svg);
  @include icon-mask-image(url(/assets/icons/vision.svg));
}

i.fv-eye {
  // background-image: url(/assets/icons/eye.svg);
  @include icon-mask-image(url(/assets/icons/eye.svg));
}

i.fv-order {
  // background-image: url(/assets/icons/order.svg);
  @include icon-mask-image(url(/assets/icons/order.svg));
}

i.fv-cd {
  // background-image: url(/assets/icons/cd.svg);
  @include icon-mask-image(url(/assets/icons/cd.svg));
}

i.file-csv-solid {
  // background-image: url(/assets/icons/file-csv-solid.svg);
  @include icon-mask-image(url(/assets/icons/file-csv-solid.svg));
  margin-bottom: 15px;
  margin-left: 20px;
}

i.arrow-from-bottom {
  // background-image: url(/assets/icons/arrow-from-bottom.svg);
  @include icon-mask-image(url(/assets/icons/arrow-from-bottom.svg));
  height: 18px;
  width: 20px;
  background-color: white;
  opacity: 1;
}

i.fv-cancel {
  background-image: url(/assets/icons/cancel.png);
  width: 12px;
  height: 12px;
  opacity: 1;
}


