.search-area {
  display: inline-flex;
  position: fixed;
  width: 100%;
}

.search-box {
  width: 350px;
  padding-left: 15px;

  /* Below is a very ugly hack to fit the search box
     inside the nav bar for different view port widths.
     Bootstraps stacks the nav bar contents in a clumsy way
     which makes the navigation bar look odd. So had to resort
     to this way of setting the width based on break points.
   */
  @media (min-width: 992px) and (max-width: 1150px) {
    width: 260px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 0;
  }

  @media (max-width: $screen-sm-min) {
    width: 100%;
    padding-left: unset;
  }
}

.search-box {
  .ember-power-select-trigger {
    border: none;
    padding: unset;
    box-shadow: unset;
    &:focus {
      border: none;
      box-shadow: unset;
    }
  }

  .ember-power-select-option {
    min-height: 40px;
    padding: 5px 8px;
    &.ember-power-select-option--search-message,
    &.ember-power-select-option--no-matches-message {
      min-height: unset !important;
      padding-left: 5px !important;
    }

    > svg {
      margin-left: 2px;
      margin-right: 5px;
      height: 18px;
    }
  }

  .ember-power-select-options > li:nth-child(n+6) {
    line-height: 20px;
    position: relative;
    padding-left: 50px;
  }

  .ember-power-select-options > li:nth-child(5) {
    border-bottom: 1px solid #5a5a5a;
    @media (-webkit-min-device-pixel-ratio: 2) { border-bottom-width: 0.5px; }
    @media (min-resolution: 2dppx) { border-bottom-width: 0.5px; }
  }

  .ember-power-select-search-input {
    background: #141414;
    border: 1px solid #5b5b5b;
    color: #FFFFFF;
    box-shadow: unset;
    margin-top: 10px;
    border-radius: unset;
    &:focus {
      box-shadow: unset;
    }

    @media (max-width: $screen-sm-min) {
      // font-size is set to this value to prevent
      // zooming towards the input in mobile browsers
      font-size: 16px;
    }
  }
}

.search-box__icon {
  width: 25px;
  margin-left: 8px;
}

.search-box__result-artists {
  font-size: 12px;
}

.search-box__result-img {
  position: absolute;
  left: 10px;
}

.search-box__result {
  padding-left: 30px;
}

.search-box__result-img--sm {
  width: 30px;
}

.search {
  min-height: 500px;
  display: grid;
  grid-template-areas: "search-topbar"
                       "search-sidebar"
                       "search-results";
  @media (min-width: 1024px) {
    grid-template-columns: 15% 85%;
    grid-template-rows: 40px auto;
    grid-template-areas: "search-topbar search-topbar"
                         "search-sidebar search-results";
  }

  @media (max-width: $screen-sm-min) {
    grid-template-rows: max-content max-content auto;
  }
}

.search-box-height {
  min-height: 500px;
}

.search-sidebar {
  grid-area: search-sidebar;
  padding: unset;
}

.search-topbar {
  grid-area: search-topbar;
  p {
    font-size: 18px;
    color: #fff;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #5a5a5a;
  }
}

.search-results {
  grid-area: search-results;
}

.search-results__tracks {
  grid-area: track-results;
  a {
    color: white
  }
}

.search-results__track {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: "track-image track-display-name"
                       "track-image track-player";
  @media (max-width: $screen-sm-min) {
    grid-template-areas: "track-image track-display-name"
                         "track-player track-player";
  }
}

.track-image {
  grid-area: track-image;
}

.track-display-name {
  grid-area: track-display-name;
  font-size: 18px;
}

.track-player {
  grid-area: track-player;
}

.search-results__profiles {
  grid-area: profile-results;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #5A5A5A;
  margin-bottom: 25px;
}

.search-results__mixes {
  grid-area: mix-results;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #5A5A5A;
  margin-bottom: 25px;
}

.search-results__releases {
  grid-area: release-results;
  padding-bottom: 25px;
  margin-bottom: 25px;
  a {
    text-decoration: none;
  }

  .search-result {
    border-bottom: 1px solid #5A5A5A;
    padding: 25px 0 25px 0;
  }
}

.search-results__no-results {
  font-size: 16px;
  margin-top: 5px;
}

.search-results__profiles--no-results,
.search-results__releases--no-results,
.search-results__tracks--no-results,
.search-results__mixes--no-results {
  padding-bottom: unset;
  border-bottom: unset;
  margin-bottom: unset;
  display: none;
}

.search-results__suggestions {
  font-size: 14px;
}

.search-filter {
  font-size: 16px;
  padding: unset;
  @media (max-width: 1024px) {
    display: flex;
    text-align: center;
  }

  li {
    list-style: none;
    padding: 5px;
    @media (max-width: $screen-sm-min) {
      font-size: 14px;
    }
  }

  a {
    color: #5A5A5A;
    text-decoration: none;
    &:hover, &:active {
      color: #DADADA;
    }
  }
}

.search-filter__option .active {
  color: #DADADA;
}

.search-filter__option svg {
  height: 18px;
  width: 15px;
  margin-right: 0.5rem;
}

.search-results__count {
  grid-area: results-count;
  p {
    font-size: 16px;
    color: #fff;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
