.shipping-zone {
  padding-top: 15px;
  padding-bottom: 20px;
  background-color: #2b2b2b;
  margin: 10px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__title-text {
    font-weight: bold
  }

  &__title-countries {
    color: $secondary-text-color;
  }

  &__checkbox {
    color: $secondary-text-color;
  }
}
