.track-row {
  line-height: $release-track-table-row-height;
  min-height: $release-track-table-row-height;
  background: $table-row-background-variant-1;
  margin-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  display: grid;
  grid-template-columns: 5% 5% 20% 37% 5% 13% 15%;
  grid-template-areas:
    "track-image track-play-status track-name track-progress track-duration track-timestamp track-actions";

  @media (max-width: 1300px) {
    grid-template-columns: 5% 5% 20% 38% 5% 15% minmax(100px, 12%);
  }

  @media (max-width: 990px) {
    grid-template-columns: 10% 10% 30% 10% 20% 20%;
    grid-template-areas: "track-image track-play-status track-name track-duration track-timestamp track-actions";
  }
}

.track-image {
  grid-area: track-image;
  padding-bottom: 3px;
}

.track-play-status {
  grid-area: track-play-status;
  padding-left: 8px;
}

.track-name {
  grid-area: track-name;
  padding-right: 15px;
  @media (max-width: $screen-sm-min) {
    text-align: center;
  }
}

.track-progress {
  grid-area: track-progress;
}

.track-duration {
  grid-area: track-duration;
  text-align: right;
}

.track-timestamp {
  grid-area: track-timestamp;
  text-align: center;
}

.track-actions {
  grid-area: track-actions;
  padding-left: 10px;
}

.track-row {

  &:hover,
  &.active {
    background: $table-row-background-variant-1-hover;
    .track-control-button {
      color: $active-play-button-color;
    }
  }

  .ellipsis-animation {
    span {
      font-size: 14px;
    }
    margin-top: -1px;
  }

  .track-control-button {
    outline: none;
    background: transparent;
    border: none;
    padding: 0;
    height: $release-track-table-row-height;

    .icon-play,
    .icon-pause {
      display: block;
    }
  }

  .player-wrapper {
    margin-top: 10px;
    .player-play-section {
      margin-left: 0;
    }
  }

  .track-name {
    button {
      text-align: left;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
    }

    a {
      color: #dadada;
      display: block;
      width: 100%;
      &:hover, &:focus, &:after {
        text-decoration: underline;
      }
    }
  }

  .track-progress {
    position: relative;
    float: left;
    @media (max-width: 990px) {
      display: none;
    }
  }

  .track-duration {
    padding-left: 10px;
  }

  .track-action {
    float: right;
  }
}
