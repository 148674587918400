.tag-filter {
  float: right;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  margin-bottom: 10px;
  left: 15px;

  &__icon {
    margin-top: 6px;
    padding-left: 5px;
  }

  label {
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }

  &__link {
    color: $body-color;
    display: inline-block;
    width: 100%;
    &:hover, &:visited, &:focus {
      color: $body-color;
      text-decoration: none;
      outline: none;
    }
  }
}
