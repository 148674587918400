table.sales__detail_table {
  border-collapse: inherit;
  thead tr th {
    padding-top: 0;
    padding-bottom: 0;
  }

  td {
    padding: 8px !important;
    min-width: 70px;
    max-width: 260px;
  }

  tr {
    cursor: default !important;
  }

  tbody .sum-row:last-child {
    color: $body-color;
  }

  .fv-table-thumbnail:before  {
    padding-top: 12px;
  }

  .description-content {
    display: inline-block;
    max-width: calc(100% - 70px);
    vertical-align: middle;
    padding-left: 0.5em;
  }

  .sum-row {
    &.first-sum-row {
      td {
        padding-top: 24px !important;
        padding-bottom: 12px !important;
      }
    }
    color: $secondary-text-color;
    td {
      height: 45px;
    }
  }

  .price-row {
    width: 70px;
    text-align: right;
  }

  .image__cell {
    width: 70px;
  }

  .total-price__cell {
    width: 150px;
  }

  .description__cell {
    padding-right: 40px !important;
    .truncate {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      -webkit-line-clamp: 2;
    }
  }

  .padded-text-right {
    text-align: right;
    padding-right: 35px !important;
  }

  img, i {
    margin: 0 0.5em;
  }
}
