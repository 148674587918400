.feed-body {
  grid-area: body;
  display: grid;
}

.feed-body--explore {
  grid-template-columns: 100%;
  grid-template-areas: "content";
}

.feed-body--tracks {
  grid-template-columns: 100%;
  grid-template-areas: "content";
}

.feed-content {
  grid-area: content;
}

.feed {
  a:hover, a:focus, a:active {
    text-decoration: none;
  }
}

.feed .fv-box {
  height: unset;
}

.feed-header__label {
  display: inline-block;
  width: 50%;
}

.feed-header__sort {
  display: inline-block;
  width: 100%;
  .sortable-select {
    margin: 0;
  }
}

.feed-sidebar {
  grid-area: sidebar;
  display: block;
}

.sidebar-title {
  position: sticky;
  top: 10%;
  @media (max-width: $screen-sm-min) {
    position: unset;
  }
}


.sidebar-content {
  position: sticky;
  padding-top: 15px;
  top: 15%;
  @media (max-width: $screen-sm-min) {
    position: unset;
  }
}


.feed-releases {
  border-bottom: 0.5px solid #5A5A5A;
  .release__name {
    h1 {
      font-size: 28px;
    }
  }
}

.release-left-bar-mini {
  grid-area: release-left-bar-mini;
}

.release-content {
  grid-area: release-content;
}

.infinity-loader {
  font-size: 18px;
  padding: 10px;
  color: $active-play-button-color;
}
