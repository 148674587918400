.shipping-details {
  color: $secondary-text-color;
  padding: 0 8px;

  p {
    margin: 0;
  }

  .shipping-details__title {
    font-weight: 500;
    color: $body-color;
  }
}
