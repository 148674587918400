.fv-radio-button {
  cursor: pointer;
  display: inline-block;

  &__content {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
  }

  &__checkmark {
    border: solid $secondary-text-color;
    border-width: 2px;
    border-radius: 50%;
    height: $fixed-element-size;
    width: $fixed-element-size;
    display: inline-block;
  }

  &.checked {
    .fv-radio-button__checkmark {
      background-color: $primary-color;
    }
  }
}
