.shipping-zone-form {
  .ember-power-select-multiple-trigger {
    min-height: 68px;
  }

  &__calculation-radio {
    display: inline-block;
    margin-left: 15px;

    .fv-radio-button__content {
      margin-left: 5px;
    }
  }

  &__calculation-type-switcher-title {
    vertical-align: top;
  }

  &__prices-container {
    margin: 10px 0;
  }

  &__save-btn {
    text-transform: uppercase;
  }

  &__remove-btn {
    text-transform: uppercase;
    margin-right: 10px;
    background-color: transparent;
    color: $body-color;
    border: 1px solid $body-color;
  }
}
