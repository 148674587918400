$primary-color: #2bb39a;
$primary-color-2: #64aff9;
$primary-color-3: #1995fc;
$secondary-color: #5a5a5a;
$input-border-color: #5b5b5b;
$body-background-color: #1a1a1a;
$body-color: #dadada;
$main-player-height: 58px;
$footer-height: 50px;
$color-error: #a94442;
$color-track-header: #e7e7e7;
$release-track-table-row-height: 32px;
$footer-color: #141414;
$separator-color: #5c5c5c;
$table-header-color: #929292;
$table-row-background: #2b2b2b;
$table-row-background-variant-1: #2a2a2a;
$table-row-background-variant-1-hover: #333333;
$table-row-background-hover: #333333;
$table-row-action-button-hover: #666666;
$table-modal-row-background: #444444;
$table-modal-row-background-hover: #494949;
$table-modal-row-background-selected: #585858;
$modal-background-color: #1a1a1a;
$box-placeholder-color: #484848;
$love-color: #ff0000;
$decrease-color: #d44d32;
$increase-color: #05b439;
$tooltip-background: #515151;
$secondary-text-color: #777;
$badge-color: rgb(185, 183, 183);
$badge-border-radius: 4px;
$screen-size-mobileS: 370px;
$screen-size-medium: 970px;
$screen-size-large: 1133px;
$action-buttons-width: 96px;
$release-track-table-button-height: 28px;
$screen-xs-main-track-table-button-height: 24px;
$screen-mobileS-main-track-table-button-height: 20px;
$active-play-button-color: #64a8f4;

// size for checkboxes, radio buttons
$fixed-element-size: 16px;
