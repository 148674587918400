.fv-table-thumbnail,
.fv-table-thumbnail:before {
  width: 40px;
  height: 40px;
}

.fv-table-thumbnail.cirlce-thumbnail,
.fv-table-thumbnail.cirlce-thumbnail:before {
  border-radius: 50%;
}

.fv-table-thumbnail {
  &:before {
    content: "\f001";
    display: inline-block;
    padding: 10px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    background-color: $secondary-color;
  }
}

