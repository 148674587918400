.stats {
  display: flex;
  justify-content: space-between;
  @media (max-width: $screen-sm-min){
    flex-flow: wrap;
    justify-content: flex-start;
    margin-top: 0.5rem;
    .stats__item {
      margin-bottom: 0.75rem;
    }
    .stats__value {
      min-width: 115px;
    }
  }
}

.stats__item {
  flex: 0 0 auto;
}

.stats__value {
  min-width: 120px;
}

.stats__label {
  text-transform: uppercase;
  font-size: 1.5rem;
}

.stats__number {
  font-size: 4rem;
  display: inline-block;
}

.stats__change {
  display: inline-block;
  margin-left: 1rem;
  font-size: 2rem;
}
