.responsive-expanded-row {
  padding: 15px 30px 15px 38px;
  font-size: 14px;
  .row {
    margin-top: 2px;
    .cell-icon {
      margin-left: 0;
    }
    .label-part {
      color: $secondary-text-color;
    }
    .col-xs-6 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
