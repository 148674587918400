.sales__table {
  width: 100% !important;
  margin-bottom: 0;

  thead tr th {
    line-height: 4rem;
    font-weight: 500;
    color: #787878 !important;

    .lt-sort-icon {
      vertical-align: middle;
      margin-left: 4px;
      float: none;
      width: 12px;
      height: 10px;
      line-height: 40px;
      &.fa-sort-up, &.fa-sort-down {
        color: white;
      }
      @media (max-width: $screen-sm-min) {
        margin-left: 2px;
      }
    }
  }

  tbody tr {
    border-bottom: 3px solid $body-background-color;
    margin-bottom: 3px;
    cursor: pointer;
  }
  tbody tr td {
    @media (max-width: $screen-sm-min) {
      padding-right: 12px;
    }
    padding-right: 16px;
    padding-left: 0;
    height: 64px;

    &:first-child {
      padding: 0;
    }
  }
}
