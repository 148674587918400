.fv-cover-image {
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 50%;
    padding: 50% 0 0;
    margin-left: 25%;
  }

  width: 100%;
  background-image: linear-gradient(to right, #414345, #232526);
  padding: 100% 0 0;
  position: relative;
}
