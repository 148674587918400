.sub-menu {
  display: flex;
  position: relative;
  border-bottom: 1px solid #333;
  margin-bottom: 3rem;
  margin-top: 10px;
  &.small-margin {
    margin-bottom: 15px;
  }
  &.breadcrumbs-menu .sub-menu__nav {
    padding: 0;
    .sub-menu__link.active {
      border-color: transparent;
    }
  }

  .sub-menu__nav {
    text-transform: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 300;
    padding-right: 2rem;
    margin-bottom: -2px;
    line-height: 1.1;

    .sub-menu__link {
      display: inline-block;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      &.active {
        border-bottom: 2px solid #2BB39A;
        color: #a5a5a5;
      }
    }

    span {
      cursor: default;
      color: $secondary-color;
    }
  }

  @media (max-width: $screen-sm-min) {
    .sub-menu__nav {
      font-size: 14px;
      padding-right: 1rem;
    }
  }
  .sub-menu-arrow {
    margin: 1px 9px 0px;
    color: $secondary-color;
  }

  .fixed-width-link-small {
    max-width: 180px;
    @media (max-width: $screen-sm-min) {
      max-width: 120px;
    }
  }

  .fixed-width-link-big {
    max-width: 320px;
    @media (max-width: $screen-sm-min) {
      max-width: 150px;
    }
  }
}


.sub-menu {
  a:hover, a:focus, a:active {
    text-decoration: none;
  }
}

.sub-menu-fixed-field {
  .badge {
    margin-top: -8px;
    margin-left: 6px;
  }
}
