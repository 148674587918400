.dashboard-track-list {
  table thead tr th,
  table tbody tr td {
    &:first-child {
      padding-left: 14px;
    }
    &:last-child {
      padding-right: 14px;
    }
  }

  table thead tr th {
    line-height: 4rem;
    font-weight: 500;
    padding: 0 8px;
    .lt-sort-icon {
      vertical-align: middle;
      margin-left: 4px;
      float: none !important;
      width: 12px;
      line-height: 40px;
    }
  }
  table tbody tr td {
    .fv-table-thumbnail,
    .fv-table-thumbnail:before {
      width: 32px;
      height: 32px;
    }
    .fv-table-thumbnail:before {
      padding-top: 8px;
      font-size: 12px;
    }
    .btn-table-action {
      background-color: #3F3F3F;
      &:hover {
        background-color: #4e4e4e;
      }
    }
  }
  a {
    color: inherit !important;
  }
}
