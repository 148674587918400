.noUi-horizontal {
  border: 0;
  height: 3px;
  margin-top: 10px;
  background: gray;

  .noUi-handle {
    z-index: 4;
    height: 10px;
    width: 10px;
    top: -5px;
    border-radius: 5px;
    left: -2px;
    cursor: pointer;

    &:before, &:after {
      display: none;
    }
  }
}

.fv-slider__symbol {
  display: inline-block;
  padding-top: 7px;
  vertical-align: middle;

  i {
    cursor: pointer;
  }
}

.fv-slider-control {
  width: 80%;
  display: inline-block;
  margin-left: 4%;
  margin-right: 4%;
}

.fv-vertical-slider {
  .noUi-connect {
    background: white;
    box-shadow: none;
  }
  .noUi-target {
    background: #23D9B8;
    border-radius: 1px;
  }

  .noUi-handle {
    border-radius: 100px;
  }
}
