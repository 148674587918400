.track-mobile {
  .track-mobile-image,
  .track-mobile-content,
  .track-mobile-artist-names,
  .track-mobile-player-section {
    display: inline-block;
    vertical-align: middle;
  }
  .track-mobile-image {
    padding-right: 4px;
    .fv-table-thumbnail,
    .fv-table-thumbnail:before {
      width: 42px;
      height: 42px;
    }
    .fv-table-thumbnail:before {
      line-height: 1.2rem;
      padding: 14px;
    }
  }
  .track-mobile-content {
    width: calc(100% - 50px);
  }
  .track-mobile-top {
    margin-top: 2px;
    .track-mobile-name,
    .track-mobile-time {
      display: inline-block;
    }
    .track-mobile-name {
      width: 80%;
      padding-left: 2px;
      color: $body-color;
      button {
        text-align: left;
        border: none;
        outline: 0;
        background: transparent;
        width: 100%;
        padding: 0;
        font-weight: 200;
      }
    }
    .track-mobile-time {
      width: 20%;
      float: right;
      text-align: right;
      padding-right: 8px;
    }
  }

  .track-mobile-bottom {
    .main-control-button {
      width: 18px;
      margin: 0;
      vertical-align: middle;
      .icon-play,
      .icon-pause {
        font-size: 16px;
      }
      .ellipsis-animation  {
        height: 19px;
        padding: 2px 0 0 5px;
        span {
          font-size: 20px;
        }
      }
    }
    .track-mobile-player-section {
      height: 24px;
    }
    .track-mobile-artist-names {
      width: 60%;
      margin-left: 4px;
      font-size: 12px;
    }
    .track-mobile-buttons {
      & > div {
        display: inline-block;
      }
      width: 25%;
      float: right;
      text-align: right;
      .track-row__btn {
        padding: 0 4px;
        &:last-child {
          margin: 0 5px;
          padding: 2px 8px 0px;
        }
        i {
          font-size: 14px;
        }
        &.track-row__btn--like {
          vertical-align: text-bottom;
          display: none;
          &.btn-action--liked {
            display: inline-block;
          }
          &:hover {
            background-color: transparent;
          }
        }
        &.track-row__btn--details i {
          font-size: 20px;
        }
      }
    }
  }
  .track-play-section {
    display: none;
  }
}
