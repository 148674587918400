.claim {
  text-align: center;
  max-width: 500px;
  margin: 10% auto 0;

  .fv-box-list {
    grid-template-columns: 190px;
    margin-left: 30%;
  }

  .lead {
    color: #999;
    font-size: 16px;
  }

  .claim__buttons {
    padding-top: 20px;
    .btn {
      font-size: 18px;
      margin: 10px;
    }
  }

  .claim__profile-filter {
    text-align: left;
    padding: 15px 25px 15px 25px;

    .search-box {
      width: unset;

      .ember-power-select-search-input {
        height: 45px;
      }

      .ember-power-select-options > li {
        min-height: 65px;
        line-height: 20px;
        position: relative;
        padding-left: 60px;
        padding-top: 12px;
      }

      .search-box__result-img--sm {
        width: 40px;
      }

      .search-box__result {
        position: relative;
        font-size: 18px;
        padding-left: unset;
        top: 10px;
      }
    }
  }
  .claim__profile {
    margin-left: 25%;
  }

  .claim__error {
    text-align: left;
    padding: 10px;
  }

  .claim__link {
    padding-top: 20px;
  }
}

.claim__confirmation {
  font-size: 42px;
}

.claim__confirmation-count {
  font-size: 18px;
  padding-bottom: 10px;
  color: #D8D8D8;
}
