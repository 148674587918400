.release-form {
  &__purchase-option-label {
    width: 310px
  }

  .custom-artist-names-input {
    width: 160px;
    display: inline-block;

    @media (max-width: 900px) {
      margin-left: 15px;
    }
  }

  .custom-artist-names-present {
    padding-top: 8px;
  }
}
