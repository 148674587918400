.play-session-wrapper {

  .segments {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;

    .segment {
      position: absolute;
      height: 100%;
      z-index: 100000;
    }

    .segment-played {
      background-color: rgba(50, 145, 200, 0.3);
    }
  }

}

.play-session-location-section {
  line-height: 24px;
  color: #a3a3a3;

  i {
    vertical-align: middle;
    margin-right: 2px;
  }
}

.play-session__content {
  grid-area: play-session-content;
  display: grid;
  grid-template-columns: 5% 25% 15% 35% 10% 10%;
  border-bottom: 2px solid #1a1a1a;
  margin-bottom: 2px;
  background: #2b2b2b;
  @media (max-width: $screen-sm-min) {
    grid-template-columns: 15% 30% 25% 30%;
    grid-template-rows: repeat(3, auto);
    padding: 5px;
  }
}

.play-session__image {
  grid-area: 1 / span 1;
  top: 15%;
  position: relative;
  text-align: center;
  @media (max-width: $screen-sm-min) {
    top: unset;
    display: none;
  }
}

.play-session__track-name {
  grid-area: 1 / 2 / span 1;
  padding: 5px;
  top: 25%;
  position: relative;
  @media (max-width: $screen-sm-min) {
    top: unset;
    grid-area: 1 / 1 / span 1 / span 3;
  }
}

.play-session__listener {
  grid-area: 1 / 3 / span 1;
  @media (max-width: $screen-sm-min) {
    grid-area: 3 / 1 / span 1 / span 4;
  }
}

.play-session__heatmap {
  grid-area: 1 / 4 / span 1;
  @media (max-width: $screen-sm-min) {
    grid-area: 2 / 1 / span 1 / span 3;
  }
}

.play-session__played-time {
  grid-area: 1 / 5 / span 1;
  top: 25%;
  position: relative;
  text-align: center;
  @media (max-width: $screen-sm-min) {
    grid-area: 2 / 4 / span 1;
  }
}

.play-session__listened-at {
  grid-area: 1 / 6 / span 1;
  top: 25%;
  position: relative;
  text-align: center;
  @media (max-width: $screen-sm-min) {
    top: unset;
    text-align: left;
    grid-area: 1 / 4 / span 1 / span 1;
  }
}

.play-session__header {
  background: unset;
  padding: unset;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.play-session__content > div {
  padding: 5px;
}

.play-session__content > span {
  top: unset;
}
