.form-wrapper-centered {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  background: #0A0A0A;
}

.form-wrapper {
  h1 {
    color: #fff;
    text-align: center;
  }

  h2 {
    color: gray;
    font-size: 1.3rem;
    text-align: center;
  }

  .form-header {
    .btn-close {
      padding-top: 10px;
      cursor: pointer;
      color: $body-color;
      font-size: 18px;
      left: 150px;
      position: relative;
      &:hover {
        color: $secondary-color;
      }
    }
  }

  .form-fields {
    margin-top: 3rem;

    .form-selection {
      margin-top: 2rem;
      font-size: 1.5rem;
      color: gray;

      input {
        margin-left: 10px;
        margin-right: 4px;
      }
    }

    .has-error {
      input {
        border-color: $color-error;
      }
    }
  }

  .alert {
    margin-top: 2rem;
  }

  .form-actions {
    text-align: center;

    .btn-form-action {
      width: 90%;
      display: inline-block;
      margin-top: 3rem;
      padding: 1.3rem 2rem;
    }
  }
}

.fv-form-centered {
  margin: auto;
  display: block;
  text-align: center;
  width: 300px;
}

.form-apply-cta {
  color:#aeaeae;
  a {
    color: #fff;
  }
}
