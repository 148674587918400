.dropdown-download {
  .btn-default.dropdown-toggle {
    &:hover, &:active, &:focus {
      background: #38CFB3;
    }
  }
  .dropdown-menu {
    padding-bottom: 5px;
    padding-top: 5px;
    min-width: 0;
    right: 0;
    left: unset;
  }
}
