@import "variables";
@import "ember-bootstrap/bootstrap";
@import "login-signup";
@import "buttons";
@import "forms";
@import "icons";
@import "navbar";
@import "search";
@import "uploader";
@import "player";
@import "track-form";
@import "track-row";
@import "tracks";
@import "feed";
@import "form-controls";
@import "animations";
@import "play-session";
@import "profile";
@import "donate-widget";
@import "donate-form";
@import "releases";
@import "release-left-bar";
@import "tables";
@import "common";
@import "utilities";
@import "fv-box";
@import "checkout";
@import "overlays";
@import "stats";
@import "merch";
@import "shopping-cart";
@import "pagination";
@import "index";
@import "country-with-flag";
@import "social-share";
@import "shipping/shipping-zones-container";
@import "shipping/shipping-by-weight-price";
@import "shipping/shipping-by-weight-prices-container";
@import "shipping/shipping-price-by-quantity-form";
@import "shipping/shipping-zone-form";
@import "shipping/shipping-zone";
@import "fv-radio-button";
@import "checkbox";
@import "release-form";
@import "shopping-cart/list";
@import "shopping-cart/list-item";
@import "sortable-table-head";
@import "sortable-select";
@import "tag-filter";
@import "filter-select";
@import "artist-selector";
@import "downloadable-resource";
@import "fv-purchase-list";
@import "sales";
@import "downlodable-list";
@import "privacy-switch";
@import "browser-specifics";
@import "sub-menu";
@import "static-pages";
@import "claim";
@import "notification-preference";

// Vendor
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import "ember-power-calendar";

@import "modals";

@import "ember-power-select/themes/bootstrap";

@import "ember-power-select";
@import "vendor-overrides";

@import "power-select";

// Pods
@import "overlay-actions/style";
@import "table/cell/row-toggle/style";
@import "table/row/expanded/style";
@import "table/cell/image/style";
@import "table/cell/icon-text/style";
@import "truncate-with-tooltip/style";
@import "purchase/buyer-shipping-data/style";
@import "purchase/sales-list/style";
@import "track/track-selector/style";
@import "table/cell/track-mobile/style";
@import "track/new-tracks-table/style";
@import "dashboard/dashboard-track-list/style";
@import "cover-image/style";
@import "upload/style";
@import "edit-profile/style";
@import "profile/profile-grid/style";

@import 'ember-cli-loaders/variables';
@import 'ember-cli-loaders/mixins';
@import 'ember-cli-loaders/animations/line-scale-pulse-out';

@font-face {
  font-family: "stratum-1-web";
  src: url("/assets/fonts/stratum1-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "stratum-1-web";
  src: url("/assets/fonts/stratum1-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "stratum-1-web", 'Helvetica', Arial, sans-serif;
  background: $body-background-color;
  color: $body-color;
  margin-bottom: $main-player-height;
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  font-weight: bold;
}

.copy-text-area {
  h4 {
    font-size: 2.4rem;
    text-transform: none;
    font-weight: 300;
    letter-spacing: 1px;
  }

  h5 {
    margin-top: 30px;
    font-size: 1.8rem;
  }

  p {
    margin-top: 30px;
    font-size: 1.8rem;
    color: rgb(133, 133, 133);
    font-weight: 100;
  }

  hr {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

hr {
  border-color: #333;
}


a.fv-link  {
  color: gray;

  &:hover {
    color: gray;
  }
}

a.fv-link--dark  {
  color: $secondary-color;

  &:hover {
    color: $secondary-text-color;
  }
}

a.fv-link--white  {
  color:$body-color;

  &:hover {
    color:$secondary-text-color;
  }
}

input, select, textarea {
  color: #333333;
  background-color: #fff;
}

::-webkit-scrollbar
{
  width: 8px;  /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.15);
}

::-webkit-scrollbar-thumb
{
  background: #545454;
}

div.highlight {
  padding: 10px;
  background-color: #2d2d2d;
}

.purchase-box {
  margin-bottom: 100px;
}

.footer-area {
  margin-top: 30px;
  border-top: 1px solid #333;
}

.css-loader {
  text-align: center;
  min-height: 500px;
  .line-scale-pulse-out {
    position: absolute;
    top: 30%;
    left: 50%;
    @media (max-width: $screen-sm-min) {
      top: 35%;
      left: 45%;
    }
    div {
      background-color: $body-color !important;
    }
  }
}

.css-loader--inline {
  position: relative;
  left: unset;
  min-height: 500px;
}

.svg-symbols {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden !important;
  clip: rect(0 0 0 0);
}

.svg-symbol {
  display: inline-block;
  vertical-align: middle;
}

.social-link__icon {
  width: 25px;
  height: 15px;
  color: #777;
  &:hover, &:active, &:focus {
    color: #dadada;
  }
}

i.default-avatar {
  background: #363636;
  width: 30px;
  height: 30px;
  padding: 7px 10px;
}
