.purchase-list-table {
  table-layout: fixed;
  tr {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background: $table-modal-row-background-selected !important;
    }
  }
}

.fv-purchase-list {
  margin-top: 10px;
}

.purchase-list__header,
.purchase-list-item {
  border-bottom: 2px solid #1a1a1a;
  margin-bottom: 2px;
  background: #2b2b2b;
}

.purchase-list__amount {
  text-align: right;
}

.purchase-list__download {
  clear: right;
}

.purchase-list-item__downloadables {
  padding: 0 12px 12px 15px;
}

.purchase-list__header > div,
.purchase-list-item > div:not(.purchase-list-item__downloadables) {
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  width: 12%;
  padding: 15px 12px 12px 12px;
}

.purchase-list__header {
  height: 40px;
  font-weight: bold;
  vertical-align: baseline;
  background: none;
}

.purchase-list-item > div.purchase-list-item__image,
.purchase-list__header > div.purchase-list__image {
  width: 6%;
  padding: 8px;
}

.purchase-list-item > div.purchase-list-item__description,
.purchase-list__header > div.purchase-list__name {
  width: 20%;
  text-align: left;
}

.sum-row {
  padding: 20px 10px;
}

.sum-row.total {
  text-align: right;
  width: 12%;
  float: right;
}

@media (max-width: $screen-sm-min) {
  .purchase-list__header > div,
  .purchase-list-item > div:not(.purchase-list-item__downloadables) {
    width: 100px;
  }

  .purchase-list__header,
  .purchase-list-item {
    width: 800px;
  }

  .purchase-list-item > div.purchase-list-item__image,
  .purchase-list__header > div.purchase-list__image {
    width: 6%;
    padding: 8px;
  }

  .purchase-list__header > div.purchase-list__name {
    width: 100px;
  }

  .purchase-list__amount {
    width: 675px;
    margin-right: 0;
  }

  .purchase-list-item__description {
    white-space: normal;
  }
  .fv-purchase-list {
    white-space: nowrap;
    overflow: scroll;
    min-height: 350px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}
