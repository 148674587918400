.shipping-price-by-quantity-form {
  margin-bottom: 62px;

  &__td {
    padding: 2px 5px;
    vertical-align: top;
    &.first {
      padding-left: 0px;
    }
    .form-group {
      margin-bottom: 0;
      width: 80px;
    }
    &.with-indent {
      padding-left: 10px
    }
    &.text {
      padding-top: 10px
    }
    &.value {
      .help-block {
        width: 180px
      }
    }
  }
}
