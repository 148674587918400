input[type="checkbox"] {
  position: absolute;
  left: -9999px;
  &:not(:checked) + label, &:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }
  &:not(:checked) + label:before, &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: $fixed-element-size;
    height: $fixed-element-size;
    border: solid $secondary-text-color;
    border-width: 1px;
  }
  &:not(:checked) + label:after, &:checked + label:after {
    content: '\f00c';
    font-family: FontAwesome;
    position: absolute;
    top: 2.5px;
    left: 1px;
    line-height: 0.8;
    color: $primary-color;
    transition: all 0.25s;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  &:disabled + label {
    cursor: not-allowed;
  }
}

.x-toggle-component {
  justify-content: left;
  align-content: left;
  input[type="checkbox"] {
    &:not(:checked) + label:before, &:checked + label:before,
    &:not(:checked) + label:after, &:checked + label:after {
      content: unset;
    }
  }

  .x-toggle:checked + label > .x-toggle-light.x-toggle-btn {
    background: #2BB39A;
  }

  .x-toggle-light.x-toggle-btn {
    background: #7A7A7A;
  }
}
