.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 19%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 19%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 19%;
        float: left;
    }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 19%;
    float: left;
  }
}

@media (max-width: $screen-sm-min) {
  body > div > .container {
    padding-top: 70px;
  }
}

// Redefine Bootstrap breakpoints
@media (min-width: 1150px) {
  .container {
    width: 1140px;
  }
}
@media (min-width: 1250px) {
  .container {
    width: 1250px;
  }
}

.min-height-container {
  min-height: calc(100vh - 385px); // subtract header and footer;
}

@media (min-width: 1200px) and (max-width: 1290px) {
  width: 1000px;
}

.ember-power-select-trigger {
  background: transparent;
  border-color: $input-border-color;
}

.ember-basic-dropdown-content {
  background: $body-background-color;
  border-color: #3A3A3A;
  border-radius: 0;
}

.form-dark .ember-basic-dropdown {
  .ember-power-select-trigger {
    box-shadow: unset;
    &:focus {
      box-shadow: unset;
      border-color: transparent;
    }
  }
  .ember-power-select-selected-item {
    color: #888;
  }
  .ember-power-select-dropdown.ember-basic-dropdown-content--in-place {
    background: transparent;
    border: none;
    transform: translateY(-34px);
  }
  .ember-power-select-search {
    padding: 0;
    .ember-power-select-search-input {
      background: #252525;
      box-shadow: unset;
      min-height: 34px;
      border-color: transparent;
      border-radius: 2px;
      padding: 0 10px;
      &::placeholder {
        color: #999;
      }
      &:focus {
        box-shadow: none;
        border-color: #31B49C;
      }
    }
  }
  .ember-power-select-options {
    border: 1px solid #5b5b5b;
    border-radius: 2px;
    margin-top: 7px;
    background: #1a1a1a;
  }
  .ember-power-select-option {
    padding: 2px 12px;
    color: #6b6b6b;
    &[aria-current=true] {
      color: $body-color;
    }
  }
  .ember-power-select-status-icon,
  .ember-power-select-status-icon:before {
    display: none;
  }
}

.ember-power-select-dropdown.ember-basic-dropdown-content--below,
.ember-power-select-dropdown.ember-basic-dropdown-content--in-place {
  border-style: solid;
  border-color: #3a3a3a;
  border-width: 1px 0;
  border-radius: 0 0 4px 4px;
}

.ember-power-select-option[aria-selected="true"] {
  background: rgb(143, 143, 143);
}

.ember-power-select-option[aria-current="true"] {
  background: $primary-color;
}

.ember-power-select-search-input {
  background: #2c2c2c;
  border-color: #676767;
  color: $body-color;
}

[class^="_c-notification__container"] {
  z-index: 10000 !important;
}

.alert {
  &.alert-success {
    background: transparent;
    border: 2px solid #299555;
    color: #299555;
  }
}


// Datepicker

.datepicker {
  z-index: 1030 !important;
  margin-top: 289px;
}

.datepicker-dropdown:before, .datepicker-dropdown:after {
  content: inherit;
  border: none !important;
}

.datepicker table tr td.day:hover,
.datepicker thead tr:first-child th:hover,
.datepicker table tr td span:hover {
  border-radius: 0px;
}

.datepicker table tr td span.active.active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active {
  border-radius: 0px;
}

// daterangepicker

.without-custom-range {
  .ranges li:last-child { display: none; }
}

.daterangepicker {
  border-radius: 1px;
  width: auto;
  right: 0;
  top: 0;
  left: 0;
  cursor: pointer;
  padding: 5px 5px;
  padding-right: 20px;

  .glyphicon {
    margin-right: 3px;
    margin-left: 5px;
    font-size: 13px;
  }

  .calendar {
    .daterangepicker_input {
      display: none;
    }
  }

  .calendar td {
    min-width: 0;
    padding: 0;
  }

  .ranges li {
    border-radius: 1px;
    background-color: transparent;
    border: none;
    color: #404040;
    padding: 5px 12px;
    margin-bottom: 0;

    &:hover, &.active {
      border: none;
      background: #313131;
      color: #fff;
    }
  }

  .calendar-table {
    border-radius: 0;
    background-color: transparent;
    border: none;

    th {
      border-radius: 1px;
      color: #3a3a3a;
    }
  }

  td {
    border-radius: 0;
  }

  td.disabled {
    background-color: transparent;
    opacity: 0.25;
  }

  td.available {
    color: gray;

    &:hover {
      background: #313131;
      color: #EFEFEF;
    }
  }

  td.active, td.active:hover, td.start-date, td.in-range, td.end-date {
    border-radius: 0;
    background: #313131;
    color: #EFEFEF;
  }

  td.off.available {
    color: gray;
    background-color: transparent;
    opacity: 0.6;
  }
}

.daterangepicker.opensleft:before, .daterangepicker.opensleft:after {
  content: inherit;
  border: none !important;
}

.daterangepicker-main-button {
  margin-top: -9px;
  text-transform: uppercase;
  font-size: 12px;
  color: $body-color;
  background: $body-background-color;
  border: 1px solid #505050;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 28px;
  height: 29px;
  line-height: 19px;
  position: relative;
  display: inline-block;

  i {
    color: lighten(#505050, 15);
  }
}

.daterangepicker-main-button:hover {
  color: $body-color;
  border-color: $body-color;
  i { color: $body-color }
}

.daterangepicker.isLoading {
  opacity: 0.4;
  pointer-events: none;
}

.daterangepicker:before, .daterangepicker:after {
  content: inherit;
  display: none;
  border: none !important;
}

// Tooltips

.ember-bootstrap-tooltip {
  .tooltip-arrow {
    border-bottom-color: $tooltip-background;
  }
  background: $tooltip-background;

  .tooltip-inner {
    padding: 5px 10px;
    background: $tooltip-background;
    & > * {
      color: inherit;
    }
    word-break: break-word;
  }

  &.tooltip.top .tooltip-arrow {
    bottom: -5px;
    border-top-color: $tooltip-background;
  }

  &.tooltip.bottom .tooltip-arrow {
    top: -5px;
    border-bottom-color: $tooltip-background;
  }

  &.tooltip.in{ opacity:1 ;}
}

// Pagination

.pagination > li > a {
  padding: 1px 0px;
  background-color: inherit;
  color: #B5B5B2;
  min-width: 24px;
  font-size: 12px;
  text-align: center;
  border: 0;
  font-weight: bold;
  height: 20px;
  margin-top: 3px;

  &:hover, &:focus, &.active {
    color: $body-color;
    background-color: inherit;
    border-bottom: 2px solid;
    border-color: $primary-color !important;
  }
}

.pagination > li:first-child > a {
  margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a, {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #fdfdfd;
  background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #fdfdfd;
  background-color: transparent;
}

.badge {
  position: relative;
  padding: 3px 3px 3px 3px;
  text-transform: uppercase;
  font-weight: 300;
  background: rgb(47,47,47);
  bottom: 10px;
  font-size: 10px;
}
