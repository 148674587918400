.upload-section {
  .track-start-time {
    input {
      text-align: center;
    }
  }

  .artist-selector__dropdown {
    &.with-create-option ul {
      border-color: #31B49C;
      box-shadow: none;
      li[aria-current=true] {
        color: #252525;
      }
    }
  }
}

