.preference__title, .preference__category {
  padding-bottom: 20px;
  > div {
    display: inline-block;
    vertical-align: top;
  }

  .email__toggle, .push__toggle, .follow__toggle {
    width: 75px;
  }

  .preference__label {
    width: 60%;
  }
}
