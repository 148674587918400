.shopping-cart-list-item {
  margin: 0 0 4px 0;
  min-height: 34px;
  background: $table-row-background;

  &:hover {
    background: $table-row-background-hover;
  }
  &.is-error {
    border: 1px solid $state-danger-text;
  }
  &__column {
    line-height: 30px;
    padding: 5px;
  }

  &__remove-btn {
    color: inherit;
    padding: 5px 8px;
  }
}
