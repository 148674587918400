.shipping-by-weight-price {
  &__td {
    padding: 5px;
    &.weight-measure {
      padding-right: 30px
    }
    &.value {
      color: $secondary-text-color;
    }
  }
}
