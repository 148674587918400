.privacy-switch {
  min-height: 70px;
  &__checkbox {
    padding-top: 5px;
    > div {
      display: inline-block;
      vertical-align: middle;
      padding: 0 5px;
      position: relative;
      right: 25px;
    }
  }
  &__token-input {
    @extend .form-control;
    padding-right: 20px;
  }
  &__token-label {
    padding-top: 7px;
    text-align: right;
  }
  &__token-label-container {
    padding-left: 0;
    padding-right: 0;
  }

  &__token-label-container {
    padding-left: 0;
    padding-right: 0;
  }
  &__token-input-container {
    padding-left: 0;
  }

  &__link {
    color: #dadada;
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 58px);
    &:hover, &:active {
      color: $secondary-color;
    }
  }

  &__share-icon {
    position: absolute;
    top: 11px;
    right: 20px;
  }
}
