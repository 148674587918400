.fv-modal {
  border-radius: 1px;
  background: $modal-background-color;
  padding: 25px;
  width: 75%;
  max-width: 850px;

  @media (max-width: 870px) {
    width: 90%;
    min-width: 90%;
  }

  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

.modal-content {
  border-radius: 1px;
  background: $modal-background-color;
  padding: 20px;
  max-width: 850px;


  @media (max-width: 870px) {
    min-width: 90%;
  }
}

.modal-content {
  padding: 0 20px 20px;
}

.modal-dialog {
  margin-top: 50px;
}

.fv-modal--md {
  width: 45%;
}

.fv-modal--sm {
  width: 35%;
}

.fv-modal-header {
  border-bottom: 1px solid #505050;

  &.modal-header {
    padding: unset;
    border-bottom: unset;
  }

  p {
    font-weight: 600;
  }

  .close {
    padding-top: 20px;
    opacity: 0.8;
    font-size: 36px;
    color: #FFF;
    &:hover {
      color: #A3A3A3;
    }
  }

  .btn-close {
    cursor: pointer;
    font-size: 16px;
    right: 30px;
    position: absolute;
    top: 30px;

    &.png-icon {
      top: 35px;
    }
  }
}

.fv-modal-body {
  margin-top: 20px;

  &.modal-body {
    margin-top: unset;
    padding: unset;
  }
}

.ember-modal-overlay.translucent {
  background-color: rgba(40, 40, 40, 0.9);
}

.fv-modal-footer {
  .close-link {
    margin-right: 15px;
    margin-top: 5px;
    line-height: 30px;
  }
}


