.navbar {
  @media (max-width: $screen-sm-min) {
    width: 100%;
    position: fixed;
  }
}

.navbar-header {
  .search-area__show {
    font-size: 20px;
    position: relative;
    top: 4px;
    color: #808080;
    &:hover, &:active, &:focus {
      color: #D8D8D8;
    }
  }

  .search-area__hide {
    font-size: 20px;
    padding: 18px 12px 0;
    width: 5%;
    color: #808080;
    &:hover, &:active, &:focus {
      color: #D8D8D8;
    }
  }

  .search-area__label {
    width: 90%;
    text-align: center;
    padding-top: 15px;
    font-size: 14px;
    color: #808080;
  }

  .liquid-container,
  .liquid-child {
    overflow: visible;
  }

  .liquid-container.liquid-animating {
    overflow: hidden;
  }
}

.navbar-default {
  background: #141414;
  border: 0;
  border-radius: 0;
  z-index: 1005;
}

.btn-navbar-action {
  margin-top: 10px;
  @media (max-width: $screen-sm-min) {
    margin-right: 10px;
  }
}

.btn-navbar-action.navbar-nav {
  margin: 2px 0;
}

.btn-navbar-action-offset-left {
  margin-left: 10px;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #D8D8D8;
  text-decoration: none;
}

.navbar-default .navbar-nav > li > a {
  padding: 15px 15px;
  @media (max-width: $screen-sm-min) {
    padding: 5px 10px;
    margin: 5px;
    color: #D7D7D7;
    &:hover, &:active, &:focus {
      color: #777777;
    }
  }
  &.navbar-avatar {
    padding: 0;
    padding-left: 10px;
    @media (max-width: $screen-sm-min) {
      padding-left: unset;
      padding-right: 10px;
    }
  }
}

.navbar-brand.hide {
  display: none;
}

.navbar-default .navbar-collapse {
  border-top: 0;
}

.navbar-default .navbar-collapse .btn-navbar-login {
  color: $body-color;
}

.navbar-pulldown {
  @media (max-width: $screen-sm-min) {
    position: relative;
    z-index: -1;
  }
}

.nav .nav-divider {
  background-color: unset;
  border-top: 1px solid #3A3A3A;
}

.nav .navbar-divider {
  background-color: #232323;
  width: auto;
  height: 1px;
  margin: 0px 15px;
  @media (min-width: $screen-sm-min) {
    width: 1px;
    height: 34px;
    margin: 8px 0px;
  }
}

.navbar-right {
  .dropdown .dropdown-toggle {
    padding-right: 0;
  }
  .dropdown-menu {
    left: 0;
  }
}

.dropdown {
  i {
    width: 20px;
  }
}

.navbar-toggle {
  position: relative;
  float: unset;
  padding: 20px 0;
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  .icon-bar {
    color: #FFFFFF;
  }
}

.navbar-default .navbar-toggle {
  border: 0;
  border-color: unset;
  &:hover, &:active, &:focus {
    background-color: unset;
  }
}

.navbar-default .navbar-toggle {
  &.collapsed .icon-bar {
    background-color: #808080;
  }

  .icon-bar {
    background-color: #D8D8D8;
  }
}

.navbar-brand {
  @media (max-width: $screen-sm-min) {
    font-size: 22px;
    padding-top: 18px;
  }
}

// hide the search bar inside the burger menu
.navbar-collapse > .navbar-nav .search-box {
  @media (max-width: $screen-sm-min) {
    display: none;
  }
}

.navbar-header__mobile {
  padding-top: 18px;
  .fv-link {
    padding: 5px;
    text-decoration: none;
    &:hover, &:active, &:focus {
      color: #D8D8D8;
    }
  }
}

.navbar-toggle__mobile {
  position: absolute;
  left: 55px;
}

.btn-navbar-avatar.open .dropdown-menu {
  background-color: #252525;
  border: 1px solid #333;
  > li > a {
    color: #FFF !important;
    padding: 5px 5px 5px 15px;
    &:hover, &:active, &:focus {
      background-color: unset;
      color: #7A7A7A !important;
    }
    &.btn {
      padding: 5px;
    }
  }

  .divider {
    background-color: #333;
  }
}

.btn-navbar-avatar.open .dropdown-menu {
  position: absolute;
  top: 5px;
  left: -105px;
}

.btn-navbar-mobile-app {
  background: #2bb39a;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  border: 1px solid #2bb39a;
  text-transform: uppercase;
}
