.fv-table {
  thead tr th {
    border-bottom: none;
    .lt-sort-icon {
      width: 9px;
      display: inline-block;
    }
  }

  tbody tr {
    border-bottom: 2px solid $body-background-color;
    margin-bottom: 2px;
    background: $table-row-background;

    &:hover {
      background: $table-row-background-hover;
    }
  }

  tbody tr td {
    vertical-align: inherit;
    border: none;
    padding: 8px;
  }

  .order-number-column {
    width: 10%;
  }

  .table-cell-link {
    color: #f0f0f0;

    &:hover {
      color: #fff;
    }
  }

  .btn-table-action {
    padding: 3px 7px;
    color: inherit;
  }

  .sum-row {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}

.fv-table-modal {
  tbody tr {
    border-bottom: 2px solid $modal-background-color;
    background: $table-modal-row-background;

    &:hover {
      background: $table-modal-row-background-hover;
    }
  }
}

.fv-table.fv-table-scrollable {
  thead, tbody { display: block; }

  tbody {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

img.table-thumbnail {
  width: 30px;
  height: 30px;
  border-radius: 20px;
}

i.table-thumbnail {
  width: 30px;
  border-radius: 20px;
  background: #000000;
  padding: 10px;
  font-size: 10px;
}

.table {
  & > thead > tr > th {
    border-bottom: none;
  }
  margin-bottom: 10px;
}
