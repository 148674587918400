// .form-image-wrapper {
//   height: 250px;
//   width: 250px;

//   img {
//     width: 100%;
//   }

//   canvas {
//     border: 1px solid #5b5b5b;
//   }

//   .image-upload-controls {
//     position: absolute;
//     margin-top: -70px;
//     margin-left: 60px;

//     &.image-present {
//       margin-top: 20px;
//     }
//   }

//   .btn-replace-image {
//     margin-top: 5px;
//   }

//   &.has-error {
//     canvas {
//       border-color: $color-error;
//     }
//   }
// }
