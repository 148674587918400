$power-select-feedback-right: 16px;

.ember-power-select-trigger, .power-select-multiple-trigger {
  @extend .truncate;
  padding: 2px;
  padding-right: 11px;
  min-height: 34px;
  height: auto;
  border-radius: 2px;

  &~.form-control-feedback {
    right: $power-select-feedback-right;
  }
  .ember-power-select-multiple-options {
    margin-right: 45px;
  }
  .ember-power-select-multiple-option {
    .country-with-flag__flag {
      padding-top: 4px;
    }
  }
}

.ember-basic-dropdown~.form-control-feedback {
  right: $power-select-feedback-right;
}

.has-success {
  .ember-power-select-trigger {
    border-color: $state-success-text;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
    &:focus {
      border-color: darken($state-success-text, 10%);
      $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($state-success-text, 20%);
      @include box-shadow($shadow);
    }
  }
}

.has-error {
  .ember-power-select-trigger {
    border-color: $state-danger-text;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
    &:focus {
      border-color: darken($state-danger-text, 10%);
      $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($state-danger-text, 20%);
      @include box-shadow($shadow);
    }
  }
}

.ember-basic-dropdown-content.ember-power-select-dropdown {
  transform: none;
  border-top: none;
  border-radius: 0;
  border: 1px solid;
  border-color: $input-border-color;
}

.ember-power-select-trigger.ember-basic-dropdown-trigger {
  .ember-power-select-status-icon {
    border: none;
    position: unset;
    display: unset;
    &:before {
      content: '\f106';
      font-family: FontAwesome;
      position: absolute;
      top: -1px;
      right: 5px;
      margin: auto;
      font-size: 17px;
      color: #999;
    }
  }

  &[aria-expanded=true] {
    border-radius: 0;
    .ember-power-select-status-icon:before {
      content: '\f107';
    }
  }
  &:focus {
    border-color: $input-border-color;
    box-shadow: none;
  }
}

.ember-power-select-trigger--active {
  box-shadow: none;
  &[aria-expanded=true] {
    border-radius: 0;
  }
}
