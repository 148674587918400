.filter-select {
  min-width: 200px;
  margin-bottom: 10px;
  .ember-power-select-trigger {
    // override power select's default text-overflow
    // to hide ellipsis when width is set to 100%
    text-overflow: unset;
  }
}

.filter-select__link {
  color: $body-color;
  display: inline-block;
  width: 100%;
  &:hover, &:visited, &:focus {
    color: $body-color;
    text-decoration: none;
    outline: none;
  }
}

.filter-select__label {
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}
