$jumbo-box-bg: #161616;
$jumbo-box-heading: #e4e4e4;
$jumbo-box-title: #f6f9fe;
$jumbo-box-subtitle: #aaaaaa;

.section {
  // margin: 30px 0;
  padding: 50px 0;
  border-bottom: 1px solid #333;

  h3:first-child {
    margin-top: 0;
  }

  p.lead {
    color: #999;
    font-size: 16px;
  }

  &:last-child {
    border-bottom: none;
  }
}

.section-jumbo {
  h2 {
    font-size: 25px;
    letter-spacing: 2px;
    color: #999;
  }

  h3 {
    font-size: 35px;
    @media (max-width: $screen-sm-max) {
      font-size: 32px;
    }
  }

  .wrap {
    max-width: 510px;
  }

  .jumbo-box {
    background: $jumbo-box-bg;
    display: inline-block;
    width: 100%;
    padding: 40px;
    margin-bottom: 30px;
    border-radius: 2px;

    h3 {
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 30px;
      color: $jumbo-box-heading;
    }

    .jumbo-box-title {
      display: block;
      font-size: 56px;
      font-weight: 900;
      line-height: 56px;
      margin-bottom: 20px;
      color: $jumbo-box-title;

      .all-in-one {
        background: linear-gradient(30deg, #d4a8e4 20%, #b4adf7 30%, #a0d1e6 40%, #c2e6b6 50%, #eede90 60%, #efd499 70%, #fa98a2 80%);
        background-size: 100% 100%;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }

      .bandcamp {
        color: #6dbf97;
      }

      .soundcloud {
        color: #e35c2c;
      }

      .mixcloud {
        color: #a721d0;
      }
    }

    .jumbo-box-subtitle {
      display: block;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      color: $jumbo-box-subtitle;
    }

    @media (max-width: $screen-sm-max) {
      padding: 30px 20px;
      margin-bottom: 10px;

      h3 {
        font-size: 18px;
      }

      .jumbo-box-title {
        font-size: 40px;
        line-height: 40px;

        br {
          display: none;
        }
      }

      .jumbo-box-subtitle {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    padding: 20px 0;
  }
}

.section-features {
  .inner {
    max-width: 250px;
    // text-align: center;
  }

  i.fv {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 13px;
    margin: 0;
    margin-bottom: 20px;
  }

  p {
    font-size: 13px;
    color: #999;
  }
}

.section-comparison {
  text-align: center;

  table {
    width: 100%;
    text-align: left;

    th {
      text-transform: uppercase;
    }
    th, td {
      padding: 4px 6px;
    }

    .feature-yes {
      color: #24E159;
    }
  }
}

.mobile-app-row {
  background-image: url("../assets/mobile-app-section-bg.svg");
  background-position: center;
  background-size: cover;
}

.mobile-app-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-left: 200px;
  padding-right: 200px;
}

@media (max-width: 991px) {
  .mobile-app-row {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .mobile-app-grid-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}


@media (max-width: 991px) {
  .section-comparison {
    table {
      td {
        font-size: 12px;
      }
      th {
        font-size: 10px;
      }
    }
  }

  .section-features {
    i.fv {
      float: left;
      margin-right: 20px;
    }

    .inner {
      max-width: none;
    }

    .feature {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.section-releases {
  h4 {
    font-weight: normal;
    font-size: 16px;
    color: #777;
  }
  a {
    color: #777;
  }
}

.section-footer {
  &__community, &__resources {
    @media (max-width: $screen-sm-min) {
      padding-bottom: 15px;
    }

    h4 {
      color: #777;
      font-size: 16px;
      font-weight: normal;
    }
    ul{
      padding-inline-start: 0;
      list-style: none;
      font-size: 16px;
      line-height: 30px;
      li {
        a {
          color: #999;
          text-decoration: none;
          &:hover {
            color: #dadada;
          }
        }
      }
    }
  }
  &.social-links {
    h4 {
      @media (min-width: $screen-sm-max) {
        float: right;
      }
    }
    ul {
      @media (min-width: $screen-sm-max) {
        float: right;
      }
    }
  }
}

.social-links {
  h4 {
    font-size: 28px;
    color: #777;
  }
  ul {
    color: $secondary-text-color;
    padding-inline-start: 0;
    list-style: none;
    font-size: 24px;
    li {
      display: inline-block;
      padding-right: 10px;
      line-height: 30px;
      a {
        color: inherit;
        &:hover {
          color: $body-color;
        }
      }
    }
  }
}
